import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import UserService from '../../../services/User.service';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectUserInformation, setAuth } from '../../../store/reducers';
import type { ReducerAction } from '../../../types/navigationIndex.types';
import type { Doctor } from '../../../types/ninox.types';
import { Header } from '../../organisms/Header/Header';
import { StepOneOfflineAppointment } from './OfflineAppointmentSteps/StepOneOfflineAppointment';
import { StepThreeOfflineAppointment } from './OfflineAppointmentSteps/StepThreeOfflineAppointment';
import { StepTwoOfflineAppointment } from './OfflineAppointmentSteps/StepTwoOfflineAppointment';
import style from './OfflineAppointment.module.scss';

const cx = classNames.bind(style);

type OfflineAppointmentProps = {
  currentPage: number;
  setCurrentPage: React.Dispatch<ReducerAction>;
};

const offlineAppointmentClasses = cx({ OfflineAppointment: true });

export function OfflineAppointment({ currentPage, setCurrentPage }: OfflineAppointmentProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [appointmentInfo, setAppointmentInfo] = useState({});
  const [mainDoctor, setMainDoctor] = useState<{
    doctorId: number;
    doctor: Doctor;
  }>();

  const userInformation = useAppSelector(selectUserInformation);
  // clean up appointments -> remove excused and cancelled and expired
  const filteredPatientAppointments = userInformation?.appointment?.filter(
    ({ carriedOut }) => carriedOut !== 2 && carriedOut !== 3 && carriedOut !== 4
  );

  const firstAppointments = filteredPatientAppointments.filter((a) => String(a.appointmentType) === '1');
  // appointments are sorted by date. If we should have more than one, the last element is the most recent
  if (firstAppointments.length === 0) {
    return (
      <>
        <div>Etwas ist schiefgelaufen. Bitte kontaktieren Sie den Support (NO_FIRST_APPOINTMENT_FOUND)</div>
      </>
    );
  }
  const latestFirstAppointment = firstAppointments[firstAppointments.length - 1];
  const location = latestFirstAppointment.practice;

  useEffect(() => {
    (async () => {
      UserService.getDoctors()
        .then((res) => {
          if (res.error) {
            if (res.message.response.status === 401) {
              dispatch(setAuth({ isAuthenticated: false }));
              navigate('/login');
            }
            console.error(res.message);
          }

          if (!res.data || res.data.length === 0) {
            navigate('/error', {
              state: { message: 'Bitte vereinbaren Sie einen Ersttermin, bevor Sie einen Folgetermin vereinbaren.' },
            });

            return;
          }

          const mainDoc = res.data.find(
            (doctor: { doctorType: string | number }) => doctor.doctorType === 'main' || doctor.doctorType === 1
          );
          setMainDoctor(mainDoc);
        })
        .catch((err) => {
          console.error(err.message);
        });
    })();
    setAppointmentInfo({
      ...appointmentInfo,
      location,
    });
    const reducerType = {
      type: '[UPDATE]',
      payload: {
        offlineAppointmentIndex: 1,
      },
    };
    setCurrentPage(reducerType);
  }, []);

  if (Object.entries(appointmentInfo).length === 0 || !mainDoctor?.doctorId) {
    return null;
  }

  let pageToRender;
  switch (currentPage) {
    case 1:
      pageToRender = (
        <StepOneOfflineAppointment
          setCurrentPage={setCurrentPage}
          appointmentInfo={appointmentInfo}
          setAppointmentInfo={setAppointmentInfo}
          doctor={mainDoctor}
        />
      );
      break;
    case 2:
      pageToRender = (
        <StepTwoOfflineAppointment
          setCurrentPage={setCurrentPage}
          appointmentInfo={appointmentInfo}
          setAppointmentInfo={setAppointmentInfo}
          doctor={mainDoctor}
        />
      );
      break;
    case 3:
      pageToRender = (
        <StepThreeOfflineAppointment
          setCurrentPage={setCurrentPage}
          appointmentInfo={appointmentInfo}
          setAppointmentInfo={setAppointmentInfo}
        />
      );
      break;
    default:
      pageToRender = <></>;
  }

  return (
    <React.Fragment>
      <Header showBackButton={true} barTitle='Folgetermin buchen' />
      <div className={offlineAppointmentClasses}>{pageToRender}</div>
    </React.Fragment>
  );
}
