import React from 'react';
import classNames from 'classnames/bind';
import { format, setDefaultOptions } from 'date-fns';
import { de } from 'date-fns/locale';
import type { ReducerAction } from '../../../../types/navigationIndex.types';
import type { DoctorSlot } from '../../../../types/user.types';
import { Button, Modal } from '../../../atoms';
import { ProgressStep, ProgressSteps } from '../../../organisms/ProgressSteps/ProgressSteps';
import classes from './StepTwoOnlineAppointment.module.scss';

const cx = classNames.bind(classes);

type StepTwoOnlineAppointmentProps = {
  setCurrentPage: React.Dispatch<ReducerAction>;
  error: boolean;
  setError: (value: boolean) => void;
  reloadDoctorSlots: () => void;
  displayDoctorSlots: DoctorSlot[];
  selectedSlot?: DoctorSlot;
  setSelectedSlot: (value: DoctorSlot) => void;
  selectedDate: Date;
};

const stepsOnlineAppointmentClasses = cx({ StepTwoOnlineAppointment: true, StepsContainer: true });
const infoTextClasses = cx({ InfoText: true });
const selectedDateContainerClasses = cx({ SelectedDateContainer: true });
const selectedDateClasses = cx({ SelectedDate: true });
const slotsContainerClasses = cx({ SlotsContainer: true });
const slotClasses = cx({ Slot: true });
const slotSelectedClasses = cx({ Slot: true, Selected: true });
const slotTextClasses = cx({ SlotText: true });
const slotButtonClasses = cx({ SlotButton: true });

export function StepTwoOnlineAppointment({
  setCurrentPage,
  error,
  setError,
  reloadDoctorSlots,
  displayDoctorSlots,
  selectedSlot,
  setSelectedSlot,
  selectedDate,
}: StepTwoOnlineAppointmentProps) {
  // set locale for date-fns
  setDefaultOptions({ locale: de });

  return (
    <React.Fragment>
      <Modal
        modalVisible={error}
        setModalVisible={setError}
        cb={reloadDoctorSlots}
        disableSecondaryButton={true}
        primaryButtonText='Schließen'
      >
        Der Termin ist leider schon vergeben. Bitte wählen Sie einen anderen Termin.
      </Modal>
      <div className={stepsOnlineAppointmentClasses}>
        <ProgressSteps>
          <ProgressStep step={1} title='Wähle ein Datum' active />
          <ProgressStep step={2} title='Wähle eine Uhrzeit' active />
          <ProgressStep step={3} title='Bestätigung' />
          <ProgressStep step={4} title='Zahlung' />
        </ProgressSteps>
        <div className={infoTextClasses}>Bitte wählen Sie die Uhrzeit für Ihre Videosprechstunde.</div>
        <div className={selectedDateContainerClasses}>
          <div className={selectedDateClasses}>
            Verfügbare Termine am <b>{format(selectedDate, 'PPP')}</b>
          </div>
          <a
            onClick={() => {
              const reducerType = {
                type: '[UPDATE]',
                payload: {
                  onlineAppointmentIndex: 1,
                },
              };
              setCurrentPage(reducerType);
            }}
          >
            Datum ändern
          </a>
        </div>
        <div style={{ width: '100%' }}>
          <h4>Wählen Sie bitte die Uhrzeit</h4>
        </div>
        <div className={slotsContainerClasses}>
          {displayDoctorSlots.map((slot: DoctorSlot) => (
            <div
              key={slot.start}
              className={selectedSlot === slot ? slotSelectedClasses : slotClasses}
              onClick={() => setSelectedSlot(slot)}
            >
              <div className={slotTextClasses}>
                {new Date(slot.start).toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false,
                })}{' '}
                Uhr
              </div>
              {selectedSlot === slot && (
                <Button
                  disabled={!selectedSlot}
                  small={true}
                  className={slotButtonClasses}
                  inlineBlock={true}
                  onClick={() => {
                    const reducerType = {
                      type: '[UPDATE]',
                      payload: {
                        onlineAppointmentIndex: 3,
                      },
                    };
                    setCurrentPage(reducerType);
                  }}
                >
                  Termin buchen
                </Button>
              )}
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}
