import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { format, setDefaultOptions } from 'date-fns';
import { de } from 'date-fns/locale';
import AppointmentService from '../../../../services/Appointment.service';
import { Button, Icon, LinkButton, Loading } from '../../../atoms';
import classes from './StageElements.module.scss';

type FollowUpAppointmentBookedProps = {
  appointmentInformation: any;
};

const cx = classNames.bind(classes);

const circleWithIconClasses = cx({ CircleWithIcon: true });
const calendarDayContainerClasses = cx({ CalendarDayContainer: true });
const calendarDayNumberClasses = cx({ CalendarDayNumber: true });
const circleTextClasses = cx({ CircleText: true });
const descriptionSectionClasses = cx({ DescriptionSection: true });
const descriptionTitleClasses = cx({ DescriptionTitle: true });
const descriptionTextClasses = cx({ DescriptionText: true });
const descriptionTextSmallMarginClasses = cx({ DescriptionText: true, DescriptionTextSmallMargin: true });
const descriptionTextSemiBoldClasses = cx({ DescriptionText: true, DescriptionTextSemiBold: true });
const actionButtonsClasses = cx({ ActionButtons: true });

export const FollowUpAppointmentBooked = (props: FollowUpAppointmentBookedProps) => {
  const { appointmentInformation } = props;
  const appointmentStartDate = new Date(appointmentInformation.startDate);
  const appointmentId = appointmentInformation.id;

  // set locale for date-fns
  setDefaultOptions({ locale: de });

  const startDate = new Date(appointmentInformation.startDate);
  const endDate = new Date(appointmentInformation.endDate);
  const differenceInMs = endDate.getTime() - startDate.getTime();
  const appointmentDuration = Math.round(differenceInMs / 60000);

  const isPaymentPendingOrExpired =
    appointmentInformation.carriedOut === 5 ||
    appointmentInformation.carriedOut === 'paymentPending' ||
    appointmentInformation.carriedOut === 4 ||
    appointmentInformation.carriedOut === 'paymentExpired';
  const isOnline = appointmentInformation.type === 1 || appointmentInformation.type === 'online';

  const [isLoading, setLoading] = useState(false);
  const pay = async () => {
    setLoading(true);

    const response = await AppointmentService.payAppointment(appointmentId);
    console.log('GOT RESPONSE', response);
    // Do the redirect if there is one.
    if (response.data.redirectUrl) {
      window.location.href = response.data.redirectUrl;

      return;
    }

    setLoading(false);

    if (response.error) {
      console.error(response.message);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className={circleWithIconClasses}>
        <div className={calendarDayContainerClasses}>
          <Icon icon='calendar' size={70} color={'white'} />
          <div className={calendarDayNumberClasses}>{format(appointmentStartDate, 'd')}</div>
        </div>
        <div className={circleTextClasses}>
          {isOnline ? `Videosprechstunde` : `Termin`}
          <br />
          gebucht
        </div>
      </div>
      <div className={descriptionSectionClasses}>
        <div className={descriptionTextSmallMarginClasses}>
          {isOnline ? `Ihre Videosprechstunde ` : `Ihr Folgetermin `} wurde gebucht und findet statt am
        </div>
        <div className={descriptionTitleClasses}>{format(appointmentStartDate, 'PPPp')}&nbsp;Uhr</div>
        <div className={descriptionTextSemiBoldClasses}>
          Arzt: {`${appointmentInformation.doctorTitle} ${appointmentInformation.doctorName}`}
          <br />
          Dauer: {appointmentDuration} Minuten
        </div>
        {isOnline ? null : (
          <div className={descriptionTextClasses}>
            <span className={descriptionTextSemiBoldClasses}>Therapiezentrum</span>
            <br />
            {appointmentInformation.practice.name}
            <br />
            {appointmentInformation.practice.address[0].street} {appointmentInformation.practice.address[0].houseNumber}
            <br />
            {appointmentInformation.practice.address[0].postcode} {appointmentInformation.practice.address[0].city}
          </div>
        )}
        {isPaymentPendingOrExpired ? (
          <>
            <div className={descriptionTextClasses}>
              <span className={descriptionTextSemiBoldClasses}>
                Ihre Zahlung ist noch nicht abgeschlossen. Ihr Termin wird bis zum Zahlungseingang für 4 Stunden
                reserviert.
              </span>
            </div>
            <div className={actionButtonsClasses}>
              <Button disabled={isLoading} onClick={pay}>
                Zum Zahlung...
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className={descriptionTextClasses}>
              {isOnline
                ? `Bitte erscheinen Sie fünf Minuten vor dem Termin in der Videosprechstunde.`
                : `Bitte erscheinen Sie 15 Minuten vor dem Termin in dem Therapiezentrum.`}
            </div>
            {isOnline && (
              <div className={actionButtonsClasses}>
                <LinkButton href={`/appointment/${appointmentId}`} target='_blank'>
                  Zur Videosprechstunde
                </LinkButton>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
