import React from 'react';
import { useNavigate } from 'react-router-dom';
import { daysSinceLastAppointment } from '../../../../helper/daysSinceLastAppointment';
import { isPrescriptionWindowOpen } from '../../../../helper/isPrescriptionWindowOpen';
import type { Appointment } from '../../../../types/ninox.types';
import { Button, Icon } from '../../../atoms';
import { Alert } from '../../../molecules/Alert/Alert';
import classes from './StageElements.module.scss';

interface BookFollowUpAppointmentProps {
  allAppointments: Appointment[];
  userInformation: any;
}

export const BookFollowUpAppointment: React.FC<BookFollowUpAppointmentProps> = ({
  allAppointments,
  userInformation,
}) => {
  const navigate = useNavigate();

  const calcDaysRemaining = () => {
    const daysSincePrevAppointment = daysSinceLastAppointment(userInformation);
    if (daysSincePrevAppointment === null) {
      return null;
    }

    return userInformation.prescriptionWindowDays - daysSincePrevAppointment;
  };

  const daysRemaingToRequestPrescription = calcDaysRemaining();
  const canRequestPrescription = isPrescriptionWindowOpen(userInformation);

  const showAlert =
    canRequestPrescription && daysRemaingToRequestPrescription !== null && daysRemaingToRequestPrescription <= 21;

  const firstAppointments = allAppointments.filter((a) => String(a.appointmentType) === '1');
  // appointments are sorted by date. If we should have more than one, the last element is the most recent
  if (firstAppointments.length === 0) {
    return (
      <>
        <div>Etwas ist schiefgelaufen. Bitte kontaktieren Sie den Support (NO_FIRST_APPOINTMENT_FOUND)</div>
      </>
    );
  }
  const latestFirstAppointment = firstAppointments[firstAppointments.length - 1];
  const wasFirstAppointmentOnSite = String(latestFirstAppointment.type) === '2';

  return (
    <>
      <div className={classes.CircleWithIcon}>
        <Icon icon='calendarWithDays' size={70} color={'white'} />
        <div className={classes.CircleText}>
          {wasFirstAppointmentOnSite === true && `Folgetermin`}
          {wasFirstAppointmentOnSite !== true && `Videosprechstunde`}
          <br />
          buchen
        </div>
      </div>
      <div className={classes.DescriptionSection}>
        <div className={classes.DescriptionTitle}>
          {wasFirstAppointmentOnSite === true && `Buchen Sie jetzt Ihren Folgetermin`}
          {wasFirstAppointmentOnSite !== true && `Buchen Sie jetzt Ihre Videosprechstunde`}
        </div>
        <div className={classes.DescriptionText}>
          {wasFirstAppointmentOnSite === true &&
            `Der Folgetermin kann in Ihrem nowomed Therapiezentrum oder online stattfinden.`}
          {wasFirstAppointmentOnSite !== true && `Planen Sie ausreichend Zeit für Bearbeitung und Rezeptversand ein.`}
          {showAlert && (
            <Alert
              text={(() => {
                if (daysRemaingToRequestPrescription === 0) {
                  return 'Sie können heute noch ein Folgerezept anfordern. Danach ist ein Video-Gespräch erforderlich.';
                }
                if (daysRemaingToRequestPrescription === 1) {
                  return 'Bitte beachten Sie: Sie haben noch ein Tag Zeit, um ein Folgerezept anzufordern. Danach ist ein Video-Gespräch erforderlich.';
                }

                return `Bitte beachten Sie: Sie haben noch ${daysRemaingToRequestPrescription} Tage Zeit, um ein Folgerezept anzufordern. Danach ist ein Video-Gespräch erforderlich.`;
              })()}
            />
          )}
        </div>
        <div className={classes.ActionButtons}>
          {wasFirstAppointmentOnSite === true && (
            <Button
              onClick={() =>
                navigate('/profile/appointment-choice', { state: { firstAppointment: latestFirstAppointment } })
              }
            >
              Jetzt Termin buchen
            </Button>
          )}
          {wasFirstAppointmentOnSite !== true && (
            <Button onClick={() => navigate('/profile/online-appointment')}>Jetzt Termin buchen</Button>
          )}
        </div>
      </div>
    </>
  );
};
