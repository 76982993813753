import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { DOCTOR_SLOT_SUBTYPE } from '../../../../enums/doctorslot.enum';
import AppointmentService from '../../../../services/Appointment.service';
import UserService from '../../../../services/User.service';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectUserInformation, setAuth } from '../../../../store/reducers';
import { setAppointmentData } from '../../../../store/reducers/appointmentReducer';
import { Button, Headline, InputField, InputLoader, Loading } from '../../../atoms';
import { ContractCheckbox } from '../../../organisms/ContractCheckbox/ContractCheckbox';
import { ProgressStep, ProgressSteps } from '../../../organisms/ProgressSteps/ProgressSteps';
import classes from './StepFourOfflineFirstAppointment.module.scss';

const cx = classNames.bind(classes);

const stepsOfflineAppointmentClasses = cx({ StepFourOfflineFirstAppointment: true, StepsContainer: true });
const appointmentInfoContainerClasses = cx({ AppointmentInfoContainer: true });
const appointmentInfoLabelClasses = cx({ AppointmentInfoLabel: true });
const appointmentInfoDescriptionClasses = cx({ AppointmentInfoDescription: true });
const couponCodeFieldClasses = cx({ CouponCodeField: true });
const buttonContainerClasses = cx({ ButtonContainer: true });
const backButtonClasses = cx({ BackButton: true });

export function StepFourOfflineFirstAppointment({ setCurrentPage, firstAppointmentInfo }: any) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userInformation = useAppSelector(selectUserInformation);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [doctorStamp, setDoctorStamp] = useState('');
  const [contractAccepted, setContractAccepted] = useState(false);
  const [couponCode, setCouponCode] = useState('');

  const doctorDetails = () => {
    setLoading(true);
    UserService.getDoctor(firstAppointmentInfo.doctorId).then((res) => {
      if (res.error) {
        if (res.message.response.status === 401) {
          dispatch(setAuth({ isAuthenticated: false }));
          setLoading(false);
          navigate('/login');
        }
      }
      if (!res.data) {
        setLoading(false);

        return;
      }
      setDoctorStamp(res.data.doctor.stamp);
      setLoading(false);
    });
  };

  useEffect(() => {
    doctorDetails();
  }, []);

  const bookAppointment = async () => {
    setLoading(true);

    if (firstAppointmentInfo.selectedSlot) {
      const successPage = '/dashboard';

      const appointmentData = {
        doctorName: firstAppointmentInfo.doctorName,
        doctorId: firstAppointmentInfo.doctorId,
        startDate: firstAppointmentInfo.selectedSlot.start,
        endDate: firstAppointmentInfo.selectedSlot.end,
        type: DOCTOR_SLOT_SUBTYPE.OFFLINE,
        appointmentType: 'firstAppointment',
        practiceId: firstAppointmentInfo.location.id,
        comment: 'Von Patient erstellt',
        couponCode,
        redirectUrl: new URL(successPage, `https://${window.location.host}`).href,
      };

      // eslint-disable-next-line prettier/prettier
      const doctorResponse = await UserService.assignDoctorWithType(
        parseInt(userInformation.id, 10),
        parseInt(firstAppointmentInfo.doctorId, 10),
        'main'
      );

      if (doctorResponse.error) {
        setLoading(false);

        return;
      }

      dispatch(
        setAppointmentData({
          ...appointmentData,
          ...{
            doctorName: firstAppointmentInfo.doctorName,
            location: firstAppointmentInfo.location,
          },
        })
      );

      appointmentData.doctorName = appointmentData.doctorName.includes('.')
        ? appointmentData.doctorName.split('. ')[1]
        : appointmentData.doctorName;

      AppointmentService.createAppointment(appointmentData).then((res) => {
        if (res.error) {
          setError(true);
          if (res.message.response.status === 401) {
            dispatch(setAuth({ isAuthenticated: false }));
            navigate('/login');
            setError(false);
          }
          console.error(res.message);
          setLoading(false);
        }
        setLoading(false);
        if (res.data.data.redirectUrl) {
          window.location.href = res.data.data.redirectUrl;
        } else {
          navigate(successPage);
        }
      });
    }
  };
  if (loading) {
    return <Loading />;
  }
  const appointmentTime = `${new Date(firstAppointmentInfo.selectedDate).toLocaleString('de-DE', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  })}, ${new Date(firstAppointmentInfo.selectedSlot.start).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })} - ${new Date(firstAppointmentInfo.selectedSlot.end).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })} Uhr`;

  return (
    <div className={stepsOfflineAppointmentClasses}>
      <ProgressSteps>
        <ProgressStep step={1} title='Standort' active />
        <ProgressStep step={2} title='Wähle ein Datum' active />
        <ProgressStep step={3} title='Wähle eine Uhrzeit' active />
        <ProgressStep step={4} title='Bestätigung' active />
        <ProgressStep step={5} title='Zahlung' />
      </ProgressSteps>
      <Headline level={3}>Termin unten bestätigen.</Headline>
      <div className={appointmentInfoContainerClasses}>
        <div className={appointmentInfoLabelClasses}>Termin</div>
        <div className={appointmentInfoDescriptionClasses}>Erstgespräch</div>
      </div>
      <div className={appointmentInfoContainerClasses}>
        <div className={appointmentInfoLabelClasses}>Datum / Uhrzeit</div>
        <div className={appointmentInfoDescriptionClasses}>{appointmentTime}</div>
      </div>
      <div className={appointmentInfoContainerClasses}>
        <div className={appointmentInfoLabelClasses}>Therapiezentrum</div>
        <div className={appointmentInfoDescriptionClasses}>{firstAppointmentInfo.location.name}</div>
        <div className={appointmentInfoDescriptionClasses}>{firstAppointmentInfo.location.address}</div>
      </div>
      <div className={appointmentInfoContainerClasses}>
        <div className={appointmentInfoLabelClasses}>Arzt / Ärztin</div>
        <div className={appointmentInfoDescriptionClasses}>{firstAppointmentInfo.doctorName}</div>
      </div>
      <div className={appointmentInfoContainerClasses}>
        <div className={appointmentInfoLabelClasses}>
          <b>Gutscheincode</b>
        </div>
        <div className={appointmentInfoLabelClasses}>
          <div className={couponCodeFieldClasses}>
            <span>Falls Sie einen Gutscheincode haben, können Sie diesen hier eingeben:</span>
            <InputField onChange={setCouponCode} value={couponCode} />
          </div>
        </div>
      </div>
      <ContractCheckbox checked={contractAccepted} setChecked={setContractAccepted} doctorStamp={doctorStamp} />
      <div className={buttonContainerClasses}>
        <div className={appointmentInfoContainerClasses}>
          Ihr Termin wird bestätigt, sobald die Zahlung abgeschlossen ist. Bei Fragen oder Problemen{' '}
          <a href='/contact'>kommen Sie gern auf uns zu</a>.
        </div>
        <Button disabled={loading || !contractAccepted} onClick={bookAppointment}>
          {loading ? <InputLoader /> : <div className={classes.text}>Zur Zahlung...</div>}
        </Button>
        <a
          className={backButtonClasses}
          onClick={() => {
            const reducerType = {
              type: '[UPDATE]',
              payload: {
                offlineFirstAppointmentIndex: 3,
              },
            };
            setCurrentPage(reducerType);
          }}
        >
          Zurück
        </a>
      </div>
    </div>
  );
}
