import { Icon } from '../../atoms';

type AlertProps = {
  text?: string;
  type?: 'info' | 'success' | 'warning' | 'error';
};

const alertStyles = {
  padding: '10px 20px',
  marginBottom: '10px',
  marginTop: '10px',
  borderRadius: '4px',
  border: '1px solid',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
};

const typeStyles = {
  info: {
    backgroundColor: '#e6f0ed',
    borderColor: '#70b48c',
    color: '#34614a',
  },
  success: {
    backgroundColor: '#e6f0ed',
    borderColor: '#70b48c',
    color: '#34614a',
  },
  warning: {
    backgroundColor: '#fffde7',
    borderColor: '#ffc107',
    color: '#ff9800',
  },
  error: {
    backgroundColor: '#ffebee',
    borderColor: '#f44336',
    color: '#d32f2f',
  },
};

const iconStyles = {
  marginRight: '10px',
  fontSize: '18px',
};

export function Alert({ text, type = 'info' }: AlertProps) {
  return (
    <div style={{ ...alertStyles, ...typeStyles[type] }}>
      <div style={iconStyles}>
        <Icon icon='info' size={20} color={typeStyles[type].color} />
      </div>
      <span>{text}</span>
    </div>
  );
}
