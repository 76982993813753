import type { ReactNode } from 'react';
import React, { useEffect, useReducer } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { LoadTerms } from '../components/atoms/LoadTerms/LoadTerms';
import { CheckAuth } from '../components/molecules/CheckAuth/CheckAuth';
import { PrivateRoute } from '../components/molecules/PrivateRoute/PrivateRoute';
import { AppointmentChoice } from '../components/pages/AppointmentChoice/AppointmentChoice';
import { Appointment } from '../components/pages/Appointments/Appointment';
import { ConfirmTerms } from '../components/pages/ConfirmTerms/ConfirmTerms';
import { Contact } from '../components/pages/Contact/Contact';
import { Dashboard } from '../components/pages/Dashboard/Dashboard';
import { Documents } from '../components/pages/Documents/Documents';
import { Error } from '../components/pages/Error/Error';
import { FollowUpPrescription } from '../components/pages/FollowUpPrescription/FollowUpPrescription';
import { ForgotPassword } from '../components/pages/ForgotPassword/ForgotPassword';
import { Login } from '../components/pages/Login/Login';
import { MyAccount } from '../components/pages/MyAccount/MyAccount';
import { MyPharmacy } from '../components/pages/MyPharmacy/MyPharmacy';
import { PharmacyDetails } from '../components/pages/MyPharmacy/PharmacyDetails';
import { PharmacyList } from '../components/pages/MyPharmacy/PharmacyList';
import { OfflineAppointment } from '../components/pages/OfflineAppointment/OfflineAppointment';
import { OfflineFirstAppointment } from '../components/pages/OfflineFirstAppointment/OfflineFirstAppointment';
import { OnlineAppointment } from '../components/pages/OnlineAppointment/OnlineAppointment';
import { UnavailableAppointment } from '../components/pages/OnlineAppointment/UnavailableAppointment';
import { OnlineFirstAppointment } from '../components/pages/OnlineFirstAppointment/OnlineFirstAppointment';
import { PatientenTracking } from '../components/pages/PatientenTracking/PatientenTracking';
import { TrackingSuccess } from '../components/pages/PatientenTracking/TrackingSuccess';
import { PrivacyPolicy } from '../components/pages/PrivacyPolicy/PrivacyPolicy';
import { Step1 as QuestionnaireStep1 } from '../components/pages/Questionnaire/Step1/Step1';
import { Step2 as QuestionnaireStep2 } from '../components/pages/Questionnaire/Step2/Step2';
import { Step3 as QuestionnaireStep3 } from '../components/pages/Questionnaire/Step3/Step3';
import { Step4 as QuestionnaireStep4 } from '../components/pages/Questionnaire/Step4/Step4';
import { Step5 as QuestionnaireStep5 } from '../components/pages/Questionnaire/Step5/Step5';
import { Step6 as QuestionnaireStep6 } from '../components/pages/Questionnaire/Step6/Step6';
import { Step7 as QuestionnaireStep7 } from '../components/pages/Questionnaire/Step7/Step7';
import { Step8 as QuestionnaireStep8 } from '../components/pages/Questionnaire/Step8/Step8';
import { RegisterProcedure } from '../components/pages/Register/RegisterProcedure/RegisterProcedure';
import { RegisterSuccess } from '../components/pages/Register/Success/RegisterSuccess';
import { ResetPassword } from '../components/pages/ResetPassword/ResetPassword';
import { SetPassword } from '../components/pages/SetPassword/SetPassword';
import { TermsAndConditions } from '../components/pages/TermsAndConditions/TermsAndConditions';
import { UpdatePersonalData } from '../components/pages/UpdatePersonalData/UpdatePersonalData';
import { initialState, reactReducer } from '../helper/NavigationReducer';

type RouterProps = {
  children?: ReactNode;
};

export function Router({ children }: RouterProps): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, reactDispatch] = useReducer(reactReducer, initialState);

  function ScrollUp(): JSX.Element {
    const location = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

    return <React.Fragment></React.Fragment>;
  }

  return (
    <BrowserRouter>
      <ScrollUp />
      {children}
      <LoadTerms />
      <Routes>
        <Route path='/' element={<Navigate to='/dashboard' />} />
        <Route
          path='/dashboard'
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<RegisterProcedure />} />
        <Route path='/register/success' element={<RegisterSuccess />} />
        <Route
          path='/my-account'
          element={
            <PrivateRoute>
              <MyAccount />
            </PrivateRoute>
          }
        />
        <Route
          path='/update-personal-data'
          element={
            <PrivateRoute>
              <UpdatePersonalData />
            </PrivateRoute>
          }
        />
        <Route
          path='/questionnaire'
          element={
            <PrivateRoute>
              <Outlet />
            </PrivateRoute>
          }
        >
          <Route path='' element={<>Fragebogen</>} />
          <Route path='step-1' element={<QuestionnaireStep1 />} />
          <Route path='step-2' element={<QuestionnaireStep2 />} />
          <Route path='step-3' element={<QuestionnaireStep3 />} />
          <Route path='step-4' element={<QuestionnaireStep4 />} />
          <Route path='step-5' element={<QuestionnaireStep5 />} />
          <Route path='step-6' element={<QuestionnaireStep6 />} />
          <Route path='step-7' element={<QuestionnaireStep7 />} />
          <Route path='step-8' element={<QuestionnaireStep8 />} />
        </Route>
        <Route
          path='/patienten-tracking'
          element={
            <CheckAuth>
              <PatientenTracking />
            </CheckAuth>
          }
        />
        <Route
          path='/patienten-tracking/success'
          element={
            <CheckAuth>
              <TrackingSuccess />
            </CheckAuth>
          }
        />
        <Route
          path='/my-pharmacy'
          element={
            <PrivateRoute>
              <MyPharmacy />
            </PrivateRoute>
          }
        />
        <Route
          path='/pharmacy-list'
          element={
            <PrivateRoute>
              <PharmacyList setCurrentPage={reactDispatch} />
            </PrivateRoute>
          }
        />
        <Route
          path='/pharmacy-details/:pharmacyId'
          element={
            <PrivateRoute>
              <PharmacyDetails setCurrentPage={reactDispatch} />
            </PrivateRoute>
          }
        />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/set-password' element={<SetPassword />} />
        <Route
          path='/profile/documents'
          element={
            <PrivateRoute>
              <Documents />
            </PrivateRoute>
          }
        />
        <Route
          path='/terms-and-conditions'
          element={
            <PrivateRoute>
              <TermsAndConditions />
            </PrivateRoute>
          }
        />
        <Route
          path='/profile/appointment-choice'
          element={
            <PrivateRoute>
              <AppointmentChoice />
            </PrivateRoute>
          }
        />
        <Route
          path='/profile/online-appointment'
          element={
            <PrivateRoute>
              <OnlineAppointment setCurrentPage={reactDispatch} currentPage={state.onlineAppointmentIndex} />
            </PrivateRoute>
          }
        />
        <Route
          path='/unavailable-appointment'
          element={
            <PrivateRoute>
              <UnavailableAppointment />
            </PrivateRoute>
          }
        />
        <Route
          path='/appointment/:appointmentId'
          element={
            <PrivateRoute>
              <Appointment />
            </PrivateRoute>
          }
        />
        <Route
          path='/privacy-policy'
          element={
            <PrivateRoute>
              <PrivacyPolicy />
            </PrivateRoute>
          }
        />
        <Route
          path='/confirm-terms'
          element={
            <PrivateRoute>
              <ConfirmTerms />
            </PrivateRoute>
          }
        />
        <Route
          path='/profile/online-first-appointment'
          element={
            <PrivateRoute>
              <OnlineFirstAppointment setCurrentPage={reactDispatch} currentPage={state.onlineFirstAppointmentIndex} />
            </PrivateRoute>
          }
        />
        <Route
          path='/profile/offline-first-appointment'
          element={
            <PrivateRoute>
              <OfflineFirstAppointment
                setCurrentPage={reactDispatch}
                currentPage={state.offlineFirstAppointmentIndex}
              />
            </PrivateRoute>
          }
        />
        <Route
          path='/profile/offline-appointment'
          element={
            <PrivateRoute>
              <OfflineAppointment setCurrentPage={reactDispatch} currentPage={state.offlineAppointmentIndex} />
            </PrivateRoute>
          }
        />
        <Route
          path='/follow-up-prescription'
          element={
            <PrivateRoute>
              <FollowUpPrescription currentPage={state.followUpPrescriptionsIndex} setCurrentPage={reactDispatch} />
            </PrivateRoute>
          }
        />
        <Route
          path='/contact'
          element={
            <PrivateRoute>
              <Contact />
            </PrivateRoute>
          }
        />
        <Route
          path='/error'
          element={
            <CheckAuth>
              <Error />
            </CheckAuth>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
