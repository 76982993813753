import { useLocation, useNavigate } from 'react-router-dom';
import { signOff } from '../../../helper/UtilityFunctions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  selectAppData,
  selectAuth,
  selectUserInformation,
  setAppData,
  setAuth,
  setUserInformation,
} from '../../../store/reducers';
import { initialUserInformation } from '../../../store/reducers/initialState';
import { Icon } from '../../atoms';
import { DrawerItem } from '../DrawerItem/DrawerItem';
import { styles as inlineStyles } from './NavigationDrawer.style';
import styles from './NavigationDrawer.module.scss';

export function NavigationDrawer(props: any) {
  const auth = useAppSelector(selectAuth);
  const userInformation = useAppSelector(selectUserInformation);
  const appData = useAppSelector(selectAppData);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const currentRoute = location.pathname;

  const isPatientDeclined =
    userInformation?.isPatientDeclined !== undefined && userInformation?.isPatientDeclined === true;

  const navigate = useNavigate();

  const navigateTo = (route: string, navigationParams?: string) => {
    const reduxData = {
      ...appData,
      // TODO: warum brauchen wir diese Zeile??
      // navigationDrawerOpen: !appData.navigationDrawerOpen,
      navigationDrawerOpen: false,
    };

    dispatch(setAppData(reduxData));

    // props.setToggleBarUp(false);
    if (navigationParams) {
      navigate({
        pathname: route,
        search: navigationParams,
      });

      return;
    }
    navigate(route);
  };

  const onClickClose = () => {
    const reduxData = {
      navigationDrawerOpen: !appData.navigationDrawerOpen,
    };

    dispatch(setAppData(reduxData));
  };

  const drawerClasses = [styles.NavigationDrawer];

  if (appData.navigationDrawerOpen) {
    drawerClasses.push(styles.open);
  }

  const drawerClassesString = drawerClasses.join(' ');

  return (
    <div className={drawerClassesString} style={inlineStyles.container} {...props}>
      <div className={styles.header}>
        <div style={inlineStyles.logo} onClick={() => navigateTo('/dashboard')}>
          <Icon icon='nowomed' size={200} />
        </div>
        <div onClick={onClickClose} className={styles.close}>
          <Icon icon='cross' size={20} />
        </div>
      </div>
      <div className={styles.spacer} />
      {auth.isAuthenticated && (
        <>
          <div className={styles.item} onClick={() => navigateTo('/dashboard')}>
            <DrawerItem
              label='Dashboard'
              icon={() => <Icon icon='dashboard' color={currentRoute === '/dashboard' ? '#3db384' : 'grey'} />}
              labelStyle={currentRoute === '/dashboard' ? inlineStyles.labelGreen : inlineStyles.label}
            />
          </div>
          <div className={styles.item} onClick={() => navigateTo('/profile/documents', '?page=my-documents')}>
            <DrawerItem
              label='Meine Dokumente'
              icon={() => (
                <Icon icon='document' size={35} color={currentRoute === '/profile/documents' ? '#3db384' : 'grey'} />
              )}
              labelStyle={[
                currentRoute === '/profile/documents' ? inlineStyles.labelGreen : inlineStyles.label,
                { marginLeft: '1px' },
              ]}
            />
          </div>
        </>
      )}
      {!isPatientDeclined && auth.isAuthenticated && userInformation?.progress?.isDataConfirm && (
        <div className={styles.item} onClick={() => navigateTo('/patienten-tracking')}>
          <DrawerItem
            label='Therapie Monitoring'
            icon={() => (
              <Icon
                icon='healthTracking'
                color={currentRoute === '/patienten-tracking' ? '#3db384' : 'grey'}
                size={25}
              />
            )}
            labelStyle={currentRoute === '/patienten-tracking' ? inlineStyles.labelGreen : inlineStyles.label}
          />
        </div>
      )}
      {!isPatientDeclined && auth.isAuthenticated && userInformation?.appointment?.length > 0 && (
        <div className={styles.item} onClick={() => navigateTo('/my-pharmacy')}>
          <DrawerItem
            label='Meine Apotheke'
            icon={() => (
              <Icon icon='pharmacysmall' color={currentRoute === '/my-pharmacy' ? '#3db384' : 'grey'} size={25} />
            )}
            labelStyle={currentRoute === '/my-pharmacy' ? inlineStyles.labelGreen : inlineStyles.label}
          />
        </div>
      )}
      {auth.isAuthenticated && (
        <div className={styles.item} onClick={() => navigateTo('/my-account')}>
          <DrawerItem
            label='Mein Profil'
            icon={() => <Icon icon='user' color={currentRoute === '/my-account' ? '#3db384' : 'grey'} size={25} />}
            labelStyle={currentRoute === '/my-account' ? inlineStyles.labelGreen : inlineStyles.label}
          />
        </div>
      )}
      <div className={styles.divider}></div>
      <div className={styles.item} onClick={() => navigateTo('/contact')}>
        <DrawerItem
          label='Hilfe / Kontakt'
          icon={() => <Icon icon='kontakt' color={currentRoute === '/contact' ? '#3db384' : 'grey'} />}
          labelStyle={currentRoute === '/contact' ? inlineStyles.labelGreen : inlineStyles.label}
        />
      </div>
      <div className={styles.divider}></div>
      {auth.isAuthenticated ? (
        <div
          className={styles.item}
          onClick={() => {
            const authRedux = {
              isAuthenticated: false,
              isTermsAgree: false,
              name: '',
              isPrivacyPolicyAgree: false,
            };
            dispatch(setAuth(authRedux));
            dispatch(setUserInformation(initialUserInformation));
            // props.resetState();
            signOff({
              auth,
              nav: () => navigateTo('/login'),
            });
          }}
        >
          <DrawerItem
            label='Abmelden'
            icon={() => <Icon icon='logout' color='gray' />}
            labelStyle={inlineStyles.label}
          />
        </div>
      ) : (
        <div
          className={styles.item}
          onClick={() => {
            navigateTo('/login');
          }}
        >
          <DrawerItem
            label='Anmelden'
            icon={() => <Icon icon='logout' color='gray' />}
            labelStyle={inlineStyles.label}
          />
        </div>
      )}
      <div className={styles.divider}></div>
      <div
        className={styles.item}
        onClick={() => {
          window.open('https://www.nowomed.com/de/impressum/', '_blank');
        }}
      >
        <DrawerItem label='Impressum' labelStyle={inlineStyles.labelWithoutIcon} />
      </div>
      <div
        className={styles.item}
        onClick={() => {
          navigateTo('/privacy-policy');
        }}
      >
        <DrawerItem
          label='Datenschutz'
          labelStyle={currentRoute === '/privacy-policy' ? inlineStyles.labelGreen : inlineStyles.label}
        />
      </div>
      <div
        className={styles.item}
        onClick={() => {
          navigateTo('/terms-and-conditions');
        }}
      >
        <DrawerItem
          label='AGB'
          labelStyle={currentRoute === '/terms-and-conditions' ? inlineStyles.labelGreen : inlineStyles.label}
        />
      </div>
    </div>
  );
}
