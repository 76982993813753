import type { CSSProperties } from 'react';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../store/hooks';
import { selectUserInformation } from '../store/reducers';

export const signOff = async (props: any) => {
  const toRemove = [
    'JWT',
    'User',
    'Register1',
    'Register2',
    'Register3',
    'TermsAgree',
    'PrivacyPolicyAgree',
    'NewsletterAgree',
    'FirstTime',
  ];

  toRemove.forEach((item) => {
    localStorage.removeItem(item);
  });

  props.nav();
};

export const isInArray = (array: string[], value: string) => {
  const result = array.indexOf(value);

  return result > -1;
};

export const isInObject = (dict: any, key: string) => {
  return key in dict;
};

export const toggleArray = (
  array: string[],
  value: string,
  setFunction: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const index = array.indexOf(value);
  if (index > -1) {
    array.splice(index, 1);
  } else {
    array.push(value);
  }
  setFunction([...array]);
};

export const toggleArrayObject = (array: string[], value: string) => {
  const index = array.indexOf(value);
  if (index > -1) {
    array.splice(index, 1);
  } else {
    array.push(value);
  }

  return array;
};

export const removeFromArray = (array: string[], value: string) => {
  const index = array.indexOf(value);
  if (index > -1) {
    array.splice(index, 1);
  }

  return array;
};

export const addDays = (date: any, days: any) => {
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() + days);

  return copy;
};

export const interpolateHours = (date: Date): Date => {
  const finalDateParsed = date.toISOString().split('T');
  finalDateParsed[1] = '00:00:00Z';
  const toString: string = finalDateParsed.join('T');

  return new Date(toString);
};

export const todayStripped = () => {
  const today = new Date().toISOString();

  return today.substring(0, today.length - 5);
};

export const toDay = (dayNumber: number): string => {
  switch (dayNumber) {
    case 0:
      return 'Sonntag';
    case 1:
      return 'Montag';
    case 2:
      return 'Dienstag';
    case 3:
      return 'Mittwoch';
    case 4:
      return 'Donnerstag';
    case 5:
      return 'Freitag';
    case 6:
      return 'Samstag';
    default:
      return 'Monday';
  }
};

export const mergeStyles = (styles: CSSProperties[]) => {
  return Object.assign({}, ...styles) as CSSProperties;
};

/**
 * Takes a file name with its' extension and checks whether it includes
 * special characters or not. Dots are handled separately because they
 * are special chars that need to be part of the string only once but
 * need to be replaced in any other place.
 *
 * @param fileName A string with the name of the file (extension included)
 * @returns A formatted file name that has all special characters replaced with _
 */
export const formatFileName = (fileName: string): string => {
  /**
   * (?=[~:!\@\#$\%\^\&\*\(\)\+\=\{\};:'"|/\?><,\\\[\]\t\s\n]) => look ahead in the string for special chars, tabs, spaces, line breaks (except dots)
   * [~:!\@\#$\%\^\&\*\(\)\+\=\{\};:'"|/\?><,\\\[\]\t\s\n]+ => match any of the characters in the look ahead one or more times
   * /g => make it global so it replaces things across the whole string
   */
  const replaceRegex =
    // eslint-disable-next-line no-useless-escape
    /(?=[~:!\@\#$\%\^\&\*\(\)\+\=\{\};:'"|/\?><,\\\[\]\t\s\n])[~:!\@\#$\%\^\&\*\(\)\+\=\{\};:'"|/\?><,\\\[\]\t\s\n]+/g;
  const fileNameArray = fileName.split('.');
  let newFileName = '';
  if (fileNameArray.length >= 2) {
    fileNameArray.forEach((part, index) => {
      if (index < fileNameArray.length - 1) {
        newFileName += part.replace(replaceRegex, '_');
      }
    });

    return `${newFileName.trim() === '' ? '_' : newFileName}.${fileNameArray.at(-1)}`;
  }

  return fileName.replace(replaceRegex, '_');
};

export const useIsDeveloper = () => {
  const [isDeveloper, setIsDeveloper] = useState(false);
  const userInformation = useAppSelector(selectUserInformation);

  const demoLastNames = ['Demo', 'Test'];

  useEffect(() => {
    const developer = demoLastNames.includes(userInformation?.lastname);
    if (developer) {
      setIsDeveloper(true);
    }
  }, []);

  return isDeveloper;
};
