import { useState } from 'react';
import { format, setDefaultOptions } from 'date-fns';
import { de } from 'date-fns/locale';
import AppointmentService from '../../../../services/Appointment.service';
import { Button, Icon, LinkButton, Loading } from '../../../atoms';
import classes from './StageElements.module.scss';

type FirstAppointmentBookedProps = {
  appointmentInformation: any;
};

export const FirstAppointmentBooked = (props: FirstAppointmentBookedProps) => {
  const { appointmentInformation } = props;
  const appointmentStartDate = new Date(appointmentInformation.startDate);
  const appointmentId = appointmentInformation.id;

  // set locale for date-fns
  setDefaultOptions({ locale: de });

  const startDate = new Date(appointmentInformation.startDate);
  const endDate = new Date(appointmentInformation.endDate);
  const differenceInMs = endDate.getTime() - startDate.getTime();
  const appointmentDuration = differenceInMs / 60000;

  const isOnline = appointmentInformation.type === 1 || appointmentInformation.type === 'online';
  const isPaymentPendingOrExpired =
    appointmentInformation.carriedOut === 5 ||
    appointmentInformation.carriedOut === 'paymentPending' ||
    appointmentInformation.carriedOut === 4 ||
    appointmentInformation.carriedOut === 'paymentExpired';

  const [isLoading, setLoading] = useState(false);
  const pay = async () => {
    setLoading(true);

    const response = await AppointmentService.payAppointment(appointmentId);
    console.log('GOT RESPONSE', response);
    // Do the redirect if there is one.
    if (response.data.redirectUrl) {
      window.location.href = response.data.redirectUrl;

      return;
    }

    setLoading(false);

    if (response.error) {
      console.error(response.message);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className={classes.CircleWithIcon}>
        <div className={classes.CalendarDayContainer}>
          <Icon icon='calendar' size={70} color={'white'} />
          <div className={classes.CalendarDayNumber}>{format(appointmentStartDate, 'd')}</div>
        </div>
        <div className={classes.CircleText}>
          Termin
          <br />
          Erstgespräch
        </div>
      </div>
      <div className={classes.DescriptionSection}>
        <div className={classes.DescriptionTitle}>{format(appointmentStartDate, 'PPPp')}&nbsp;Uhr</div>
        <div className={`${classes.DescriptionText} ${classes.DescriptionTextSemiBold}`}>
          Arzt: {`${appointmentInformation.doctorTitle} ${appointmentInformation.doctorName}`}
          <br />
          Dauer: {appointmentDuration} Minuten
        </div>
        {!isOnline && (
          <div className={classes.DescriptionText}>
            <span className={classes.DescriptionTextSemiBold}>Therapiezentrum</span>
            <br />
            {appointmentInformation.practice.name}
            <br />
            {appointmentInformation.practice.address[0].street} {appointmentInformation.practice.address[0].houseNumber}
            <br />
            {appointmentInformation.practice.address[0].postcode} {appointmentInformation.practice.address[0].city}
          </div>
        )}
        {isPaymentPendingOrExpired ? (
          <>
            <div className={classes.DescriptionText}>
              <span className={classes.DescriptionTextSemiBold}>
                Ihre Zahlung ist noch nicht abgeschlossen. Ihr Termin wird bis zum Zahlungseingang für 4 Stunden
                reserviert.
              </span>
            </div>
            <div className={classes.ActionButtons}>
              <Button disabled={isLoading} onClick={pay}>
                Zum Zahlung...
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className={classes.DescriptionText}>
              <span className={classes.DescriptionTextSemiBold}>Bitte beachten Sie:</span>
              <br />
              Der Termin für Ihr Erstgespräch kann nur stattfinden, wenn Sie uns alle notwendigen Dokumente mindestens
              24 Stunden vor Ihrem Termin übermittelt haben. Details dazu finden Sie in der Bestätigungs-Mail Ihres
              Termins.
            </div>
            {isOnline ? (
              <div className={classes.ActionButtons}>
                <LinkButton href={`/appointment/${appointmentId}`} target='_blank'>
                  Zur Videosprechstunde
                </LinkButton>
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};
