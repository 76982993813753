import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { DOCTOR_SLOT } from '../../../../enums/doctorslot.enum';
import UserService from '../../../../services/User.service';
import { useAppDispatch } from '../../../../store/hooks';
import { setAuth } from '../../../../store/reducers';
import type { DoctorSlot } from '../../../../types/user.types';
import { Button, Headline, Loading } from '../../../atoms';
import { Calendar } from '../../../organisms/Calendar/Calendar';
import { ProgressStep, ProgressSteps } from '../../../organisms/ProgressSteps/ProgressSteps';
import style from './StepOneOfflineAppointment.module.scss';

const cx = classNames.bind(style);
const stepsOfflineAppointmentClasses = cx({ StepOneOfflineAppointment: true, StepsContainer: true });
const calendarContainerClasses = cx({ CalendarContainer: true });
const buttonConatinerClasses = cx({ ButtonContainer: true });

export function StepOneOfflineAppointment({ setCurrentPage, appointmentInfo, setAppointmentInfo, doctor }: any) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [doctorSlots, setDoctorSlots] = useState<DoctorSlot[]>([]);
  const [allDoctorSlots, setAllDoctorSlots] = useState<DoctorSlot[]>([]);
  const [selectedSlot, setSelectedSlot] = useState<DoctorSlot>();
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date(new Date().setHours(0, 0, 0, 0)));

  const doctorSlotsFilter = () => {
    const slotsForOfflineFirstAppointment = allDoctorSlots.filter(
      (slot: any) => slot.practiceId === appointmentInfo.location.id
    );
    setDoctorSlots(slotsForOfflineFirstAppointment);
  };

  useEffect(() => {
    if (appointmentInfo.selectedDate !== undefined) {
      setSelectedDate(appointmentInfo.selectedDate);
    }
    setLoading(true);
    UserService.getDoctorSlotsBySlotType(doctor.doctorId, DOCTOR_SLOT.FOLLOW_UP_APPOINTMENT).then((res) => {
      if (res.error) {
        if (res.message.response.status === 401) {
          dispatch(setAuth({ isAuthenticated: false }));
          setLoading(false);
          navigate('/login');
        }
      }
      if (!res.data.length) {
        setLoading(false);

        return;
      }
      setAllDoctorSlots(res.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const slots = doctorSlots.filter(
      (slot: DoctorSlot) =>
        new Date(new Date(slot.start).setHours(0, 0, 0, 0)).getTime() === new Date(selectedDate).getTime()
    );
    setSelectedSlot(slots[0]);
  }, [selectedDate, doctorSlots]);

  const next = () => {
    if (selectedSlot) {
      setAppointmentInfo({
        ...appointmentInfo,
        selectedDate,
        doctorSlots: doctorSlots.filter(
          (slot: DoctorSlot) =>
            new Date(new Date(slot.start).setHours(0, 0, 0, 0)).getTime() - selectedDate.getTime() === 0
        ),
      });
    }
    const reducerType = {
      type: '[UPDATE]',
      payload: {
        offlineAppointmentIndex: 2,
      },
    };
    setCurrentPage(reducerType);
  };

  useEffect(() => {
    doctorSlotsFilter();
  }, [allDoctorSlots]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={stepsOfflineAppointmentClasses}>
      <ProgressSteps>
        <ProgressStep step={1} title='Wähle ein Datum' active />
        <ProgressStep step={2} title='Wähle eine Uhrzeit' />
        <ProgressStep step={3} title='Bestätigung' />
        <ProgressStep step={4} title='Zahlung' />
      </ProgressSteps>
      <Headline
        level={3}
      >{`Wann soll Ihr Folgegespräch im Therapiezentrum ${appointmentInfo.location.name} stattfinden?`}</Headline>
      {doctorSlots?.length !== 0 ? (
        <div className={calendarContainerClasses}>
          <Calendar slots={doctorSlots} onChange={setSelectedDate} selectedDate={selectedDate} />
        </div>
      ) : (
        <div>
          <div>
            <div>Leider sind derzeit keine Termine bei Ihrem behandelnden Arzt / Ärztin verfügba.</div>
            <div>Bitte setzen Sie sich mit unserem Support in Verbindung.</div>
            <div>
              <div
                onClick={() => {
                  navigate('/dashboard');
                }}
              >
                Zurück zum Dashboard
              </div>
            </div>
          </div>
        </div>
      )}

      {doctorSlots?.length !== 0 && (
        <div>
          <div className={buttonConatinerClasses}>
            <Button disabled={!selectedDate || doctorSlots.length === 0} onClick={next}>
              Weiter
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
