import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import type { ReducerAction } from '../../../../types/navigationIndex.types';
import type { DoctorSlot } from '../../../../types/user.types';
import { Button, Modal } from '../../../atoms';
import { Calendar } from '../../../organisms/Calendar/Calendar';
import { ProgressStep, ProgressSteps } from '../../../organisms/ProgressSteps/ProgressSteps';
import style from './StepOneOnlineAppointment.module.scss';

const cx = classNames.bind(style);

type StepOneOnlineAppointmentProps = {
  setCurrentPage: React.Dispatch<ReducerAction>;
  hasAppointment: boolean;
  setHasAppointment: (value: boolean) => void;
  doctorSlots: DoctorSlot[];
  selectedDate: Date;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
  mainDoctor?: number;
};

const stepsOnlineAppointmentClasses = cx({ StepOneOnlineAppointment: true, StepsContainer: true });
const infoTextClasses = cx({ InfoText: true });
const calendarContainerClasses = cx({ CalendarContainer: true });
const buttonConatinerClasses = cx({ ButtonContainer: true });

export function StepOneOnlineAppointment({
  setCurrentPage,
  hasAppointment,
  setHasAppointment,
  doctorSlots,
  selectedDate,
  setSelectedDate,
  mainDoctor,
}: StepOneOnlineAppointmentProps) {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Modal
        modalVisible={hasAppointment}
        setModalVisible={setHasAppointment}
        cb={() => {
          setHasAppointment(false);
          navigate('/dashboard');
        }}
        disableSecondaryButton={true}
        primaryButtonText='Zum Dashboard'
      >
        Sie haben bereits einen Termin. Nehmen Sie den Termin erst wahr, bevor Sie einen Neuen buchen.
      </Modal>

      <div className={stepsOnlineAppointmentClasses}>
        <ProgressSteps>
          <ProgressStep step={1} title='Wähle ein Datum' active />
          <ProgressStep step={2} title='Wähle eine Uhrzeit' />
          <ProgressStep step={3} title='Bestätigung' />
          <ProgressStep step={4} title='Zahlung' />
        </ProgressSteps>
        <div className={infoTextClasses}>Bitte wählen Sie das Datum für Ihre Videosprechstunde.</div>
        <div className={calendarContainerClasses}>
          <Calendar
            slots={doctorSlots}
            onChange={setSelectedDate}
            selectedDate={selectedDate}
            mainDoctor={mainDoctor}
          />
        </div>
        <div className={buttonConatinerClasses}>
          <Button
            disabled={!selectedDate}
            inlineBlock={true}
            onClick={() => {
              const reducerType = {
                type: '[UPDATE]',
                payload: {
                  onlineAppointmentIndex: 2,
                },
              };
              setCurrentPage(reducerType);
            }}
          >
            Weiter
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}
