import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectPrescriptionInfo, setPrescriptionInfo } from '../../../../store/reducers';
import type { ReducerAction } from '../../../../types/navigationIndex.types';
import { Button, Headline, InputField } from '../../../atoms';
import { Checkbox } from '../../../molecules/Checkbox/Checkbox';
import { ProgressStep, ProgressSteps } from '../../../organisms/ProgressSteps/ProgressSteps';
import classes from './GeneralSideEffects.module.scss';

const cx = classNames.bind(classes);

type GeneralSideEffectsProps = {
  setCurrentPage: React.Dispatch<ReducerAction>;
};

export function GeneralSideEffects({ setCurrentPage }: GeneralSideEffectsProps) {
  const [groupValues, setGroupValues] = useState(['']);
  const prescriptionInfo = useAppSelector(selectPrescriptionInfo);
  const [prescriptionTemp, setPrescriptionTemp] = useState(prescriptionInfo);
  const dispatch = useAppDispatch();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // validate inputs
  useEffect(() => {
    const nothingSelected = !prescriptionTemp.generalSideEffects.length;
    const otherSelectedAndNoOtherTextEntered2 =
      prescriptionTemp.generalSideEffects.includes('sonstiges') && !prescriptionTemp?.generalSideEffectsOther?.length;
    const hasError = nothingSelected || otherSelectedAndNoOtherTextEntered2;
    if (hasError) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [prescriptionTemp]);

  const handleBackButton = () => {
    const reducerType = {
      type: '[UPDATE]',
      payload: {
        followUpPrescriptionsIndex: 1,
      },
    };
    setCurrentPage(reducerType);
  };

  const next = () => {
    // remove generalSideEffectsOther text if Other='sonstiges' is not checked
    if (!prescriptionTemp?.generalSideEffects.includes('sonstiges')) {
      setPrescriptionTemp({
        ...prescriptionTemp,
        generalSideEffectsOther: '',
      });
    }

    dispatch(setPrescriptionInfo(prescriptionTemp));

    const reducerType = {
      type: '[UPDATE]',
      payload: {
        followUpPrescriptionsIndex: 3,
      },
    };
    setCurrentPage(reducerType);
  };

  const handleInputFieldChange = (value: string) => {
    setPrescriptionTemp({
      ...prescriptionTemp,
      generalSideEffectsOther: value,
    });
  };

  // set Libraries Groupvalue checkboxes in the Redux State
  useEffect(() => {
    setPrescriptionTemp({
      ...prescriptionTemp,
      generalSideEffects: groupValues,
    });
  }, [groupValues]);

  useEffect(() => {
    setGroupValues(prescriptionInfo.generalSideEffects);
  }, []);

  const toggleGroupValue = (value: string) => {
    if (value === 'keine') {
      if (groupValues.includes('keine')) {
        setGroupValues([]);

        return;
      }

      setGroupValues(['keine']);

      return;
    }

    let newGroupValues = [...groupValues];

    if (newGroupValues.includes(value)) {
      newGroupValues = newGroupValues.filter((item: string) => item !== value);
    } else {
      newGroupValues = newGroupValues.filter((item: string) => item !== 'keine');
      newGroupValues.push(value);
    }

    setGroupValues(newGroupValues);
  };

  const checkboxValue = (value: string) => {
    return groupValues.includes(value);
  };

  const SideEffectsCheckbox = ({
    children,
    value,
    extraClasses,
  }: {
    children: string;
    value: string;
    extraClasses?: string;
  }) => {
    let extraClassesSub = cx('side-effects-checkbox');

    if (extraClasses) {
      extraClassesSub += ` ${extraClasses}`;
    }

    return (
      <Checkbox
        extraClasses={extraClassesSub}
        checked={checkboxValue(value)}
        label={children}
        onClick={() => toggleGroupValue(value)}
      />
    );
  };

  return (
    <div className={cx('GeneralSideEffects')}>
      <ProgressSteps>
        <ProgressStep step={1} title='Fragebogen' active />
        <ProgressStep step={2} title='Apotheke' />
        <ProgressStep step={3} title='Lieferbarkeit' />
        <ProgressStep step={4} title='Bestätigung' />
        <ProgressStep step={5} title='Zahlung' />
      </ProgressSteps>
      <Headline level={3}>Allgemeine Nebenwirkungen</Headline>
      <div className={cx('info-text')}>
        Bitte teilen Sie Ihrem behandelnden Arzt mit, ob im Rahmen Ihrer Cannabinoid-Therapie Nebenwirkungen und
        negative Erfahrungen aufgetreten sind.
      </div>
      <div className={cx('checkboxes')}>
        <SideEffectsCheckbox value='reactions'>Allergische Reaktionen / Unverträglichkeit</SideEffectsCheckbox>
        <SideEffectsCheckbox value='dryMouth'>
          Trockene Schleimhäute (trockener Mund / gerötete Augen)
        </SideEffectsCheckbox>
        <SideEffectsCheckbox value='appetite'>Gesteigerter Appetit (als unangenehmer Effekt)</SideEffectsCheckbox>
        <SideEffectsCheckbox value='nausea'>Übelkeit</SideEffectsCheckbox>
        <SideEffectsCheckbox value='increasedFatigue'>Erhöhte Müdigkeit (als unangenehmer Effekt)</SideEffectsCheckbox>
        <SideEffectsCheckbox value='cardioVascularProblems'>
          Herzkreislaufprobleme (Blutdruckabfall, Herzrasen, Schwindel, Unwohlsein)
        </SideEffectsCheckbox>
        <SideEffectsCheckbox value='motorLimitations'>Motorische Einschränkungen</SideEffectsCheckbox>
        <SideEffectsCheckbox extraClasses={cx('last-checkbox')} value='sonstiges'>
          Sonstiges
        </SideEffectsCheckbox>
        {prescriptionTemp.generalSideEffects.length !== 0 && groupValues.includes('sonstiges') && (
          <div className={cx('various-field')}>
            <InputField
              onChange={handleInputFieldChange}
              value={prescriptionTemp.generalSideEffectsOther}
              multiline={true}
            />
          </div>
        )}
        <div className={cx('separator')}></div>
      </div>
      <div className={cx('no-side-effects')}>
        <SideEffectsCheckbox value='keine'>Nein, ich habe keine allgemeinen Nebenwirkungen</SideEffectsCheckbox>
      </div>
      <div className={cx('button-container')}>
        <Button secondary onClick={handleBackButton}>
          Zurück
        </Button>
        <Button disabled={isButtonDisabled} onClick={next}>
          Weiter
        </Button>
      </div>
    </div>
  );
}
