import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectPrescriptionInfo, selectUserInformation, setPrescriptionInfo } from '../../../../store/reducers';
import type { ReducerAction } from '../../../../types/navigationIndex.types';
import type { PreparationListItem } from '../../../../types/ninox.types';
import { Button, Headline, InputField } from '../../../atoms';
import { Checkbox } from '../../../molecules/Checkbox/Checkbox';
import { ProgressStep, ProgressSteps } from '../../../organisms/ProgressSteps/ProgressSteps';
import classes from './ReplacePreparation.module.scss';

const cx = classNames.bind(classes);

type ReplacePreparationProps = {
  setCurrentPage: React.Dispatch<ReducerAction>;
};

type PreparationListItemReplace = PreparationListItem & {
  changeThisPreparation: boolean;
  exchangeWithThisPreparation: string;
};

export function ReplacePreparation({ setCurrentPage }: ReplacePreparationProps) {
  const userInformation = useAppSelector(selectUserInformation);
  const prescriptionInfo = useAppSelector(selectPrescriptionInfo);
  const [prescriptionTemp, setPrescriptionTemp] = useState(prescriptionInfo);

  const dispatch = useAppDispatch();

  const [lastMedicationPreparations, setLastMedicationPreparations] = useState<PreparationListItem[]>([]);

  useEffect(() => {
    if (userInformation && userInformation.medication && userInformation.medication.length > 0) {
      setLastMedicationPreparations(
        userInformation.medication[userInformation.medication.length - 1].preparationListItem
      );
    } else {
      setLastMedicationPreparations([]);
    }
  }, [userInformation]);

  const handleBackButton = () => {
    const reducerType = {
      type: '[UPDATE]',
      payload: {
        followUpPrescriptionsIndex: 7,
      },
    };
    setCurrentPage(reducerType);
  };

  const next = () => {
    dispatch(setPrescriptionInfo(prescriptionTemp));

    const reducerType = {
      type: '[UPDATE]',
      payload: {
        followUpPrescriptionsIndex: 9,
      },
    };
    setCurrentPage(reducerType);
  };

  const handleCheckboxesChange = (value: boolean, index: number) => {
    const newPrescriptionInfo = _.cloneDeep(prescriptionTemp);
    if (value === false) {
      newPrescriptionInfo.preparationQuestion[index].exchangeWithThisPreparation = '';
    }
    newPrescriptionInfo.preparationQuestion[index].changeThisPreparation = value;
    setPrescriptionTemp(newPrescriptionInfo);
  };

  const handleInputTextChange = (value: string, index: number) => {
    const newPrescriptionInfo = _.cloneDeep(prescriptionTemp);
    newPrescriptionInfo.preparationQuestion[index].exchangeWithThisPreparation = value;
    setPrescriptionTemp(newPrescriptionInfo);
  };

  const renderPreparationsItems = (preparations: PreparationListItemReplace[]) => {
    if (preparations.length === 0) {
      return <div>Preparation List Items Empty</div>;
    }

    if (preparations.length === 1) {
      return (
        <InputField
          multiline={true}
          value={preparations[0].exchangeWithThisPreparation}
          placeholder={'Austauschpräparat eingeben...\nBitte mindestens Name und Kultivar des Präparates angeben.'}
          onChange={(value) => handleInputTextChange(value, 0)}
        />
      );
    }

    return preparations.map((item: PreparationListItemReplace, index: number) => (
      <div key={index} className={cx('replace-question')}>
        <Checkbox
          extraClasses={cx('checkbox')}
          checked={item.changeThisPreparation}
          label={lastMedicationPreparations[index]?.name}
          onClick={() => handleCheckboxesChange(!item.changeThisPreparation, index)}
        />
        {item.changeThisPreparation && (
          <div className={cx('textarea-wrapper')}>
            <InputField
              multiline={true}
              value={item.exchangeWithThisPreparation}
              placeholder={'Austauschpräparat eingeben...\nBitte mindestens Name und Kultivar des Präparates angeben.'}
              onChange={(value) => handleInputTextChange(value, index)}
            />
          </div>
        )}
      </div>
    ));
  };

  return (
    <div className={cx('ReplacePreparation')}>
      <ProgressSteps>
        <ProgressStep step={1} title='Fragebogen' active />
        <ProgressStep step={2} title='Apotheke' active />
        <ProgressStep step={3} title='Lieferbarkeit' active />
        <ProgressStep step={4} title='Bestätigung' />
        <ProgressStep step={5} title='Zahlung' />
      </ProgressSteps>
      <Headline level={3}>Austauschpräparat</Headline>
      <div className={cx('info-text')}>Bitte teilen Sie uns Ihr Wunschpräparat mit.</div>
      <div className={cx('info-text', 'remark')}>
        Beachten Sie, dass abschließend Ihr behandelnder Arzt entscheidet, welches Präparat verschrieben wird. Eine
        Dosiserhöhung ist nicht möglich. Stark abweichende Präparate im THC-Gehalt zur vorherigen Medikation führen zu
        einer möglichen Ablehnung des Folgerezeptantrags.
      </div>
      <div className={cx('question')}>
        {lastMedicationPreparations.length === 1
          ? 'Welches Austauschpräparat wünschen Sie?'
          : 'Welche Präparate möchten Sie austauschen?'}
      </div>
      <div className={cx('preparations-questions')}>
        {renderPreparationsItems(prescriptionTemp.preparationQuestion)}
      </div>
      <div className={cx('button-container')}>
        <Button secondary className={cx('button')} onClick={handleBackButton}>
          Zurück
        </Button>
        <Button className={cx('button')} onClick={next}>
          Weiter
        </Button>
      </div>
    </div>
  );
}
