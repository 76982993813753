import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import _ from 'lodash';
import UserService from '../../../../services/User.service';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectPrescriptionInfo, selectUserInformation, setPrescriptionInfo } from '../../../../store/reducers';
import { initialState } from '../../../../store/reducers/followUpPrescriptionSlice';
import type { ReducerAction } from '../../../../types/navigationIndex.types';
import { Button, Headline } from '../../../atoms';
import { ProgressStep, ProgressSteps } from '../../../organisms/ProgressSteps/ProgressSteps';
import classes from './SendFollowUpPrescription.module.scss';

const cx = classNames.bind(classes);

type SendFollowUpPrescriptionProps = {
  setCurrentPage: React.Dispatch<ReducerAction>;
};

export function SendFollowUpPrescription({ setCurrentPage }: SendFollowUpPrescriptionProps) {
  const userInformation = useAppSelector(selectUserInformation);
  const prescriptionInfo = useAppSelector(selectPrescriptionInfo);
  const dispatch = useAppDispatch();
  const [lastMedicationID, setLastMedicationID] = useState<string>();

  useEffect(() => {
    if (userInformation && userInformation.medication && userInformation.medication.length > 0) {
      setLastMedicationID(userInformation.medication[userInformation.medication.length - 1].id);
    }
  }, [userInformation]);

  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

  const handleBackButton = () => {
    const reducerType = {
      type: '[UPDATE]',
      payload: {
        followUpPrescriptionsIndex: prescriptionInfo?.medicationAvailable === 'changeMedication' ? 8 : 7,
      },
    };
    setCurrentPage(reducerType);
  };

  const preparaDataForSending = () => {
    const finalData = _.cloneDeep(prescriptionInfo);
    finalData.generalSideEffects = prescriptionInfo.generalSideEffects.toString();
    finalData.psychologicalSideEffects = prescriptionInfo.psychologicalSideEffects.toString();
    finalData.medicationId = lastMedicationID;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < prescriptionInfo.preparationQuestion.length; i++) {
      finalData.preparationQuestion[i] = { preparationListItemId: prescriptionInfo.preparationQuestion[i].id };

      if (
        prescriptionInfo.preparationQuestion[i].changeThisPreparation !== undefined ||
        prescriptionInfo.preparationQuestion[i].exchangeWithThisPreparation !== undefined
      ) {
        finalData.preparationQuestion[i].changeThisPreparation =
          prescriptionInfo.preparationQuestion[i].changeThisPreparation;
        finalData.preparationQuestion[i].exchangeWithThisPreparation =
          prescriptionInfo.preparationQuestion[i].exchangeWithThisPreparation;
      }
    }

    return finalData;
  };

  const save = async () => {
    const preparedDataForSending = preparaDataForSending();

    setSubmitButtonLoading(true);

    const response = await UserService.followPrescription({
      ...preparedDataForSending,
      redirectUrl: new URL('/dashboard', `https://${window.location.host}`).href,
    });

    // Do the redirect if there is one.
    if (response.data.redirectUrl) {
      window.location.href = response.data.redirectUrl;

      return;
    }

    setSubmitButtonLoading(false);

    if (response.error) {
      console.error(response.message);
    }
    if (!response.error) {
      const reducerType = {
        type: '[UPDATE]',
        payload: {
          followUpPrescriptionsIndex: 10,
        },
      };
      dispatch(setPrescriptionInfo(initialState));
      setCurrentPage(reducerType);
    }
  };

  return (
    <div className={cx('SendFollowUpPrescription')}>
      <ProgressSteps>
        <ProgressStep step={1} title='Fragebogen' active />
        <ProgressStep step={2} title='Apotheke' active />
        <ProgressStep step={3} title='Lieferbarkeit' active />
        <ProgressStep step={4} title='Bestätigung' active />
        <ProgressStep step={5} title='Zahlung' />
      </ProgressSteps>
      <Headline level={3}>Jetzt Folgerezept anfordern</Headline>
      <div className={cx('text', 'delay-remark')}>
        Die behandelnden Ärzte prüfen Ihren Folgerezeptantrag in der Regel innerhalb von zwei Werktagen. Sie erhalten
        eine Rückmeldung per E-Mail.
      </div>
      <div className={cx('text', 'holiday-remark')}>
        <span className='bold'>Hinweis:</span> Bitte beachten Sie, dass die Bearbeitung und Übermittlung des Rezepts an
        Wochenenden und Feiertagen vereinzelt länger dauern kann.
      </div>
      <div className={cx('text')}>
        Klicken Sie hier, um mit der Bezahlung fortzufahren. Der Eingang Ihres Antrags wird bestätigt, sobald die
        Zahlung abgeschlossen wurde. Bei Fragen oder Problemen, <a href='/contact'>kommen Sie gern auf uns zu</a>.
      </div>
      <div className={cx('button-container')}>
        <Button secondary className={cx('button')} onClick={handleBackButton}>
          Zurück
        </Button>
        <Button className={cx('button')} disabled={submitButtonLoading} onClick={save}>
          Zur Zahlung...
        </Button>
      </div>
    </div>
  );
}
