import React, { useEffect, useState } from 'react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { selectUserInformation, setWindowData } from './store/reducers';
import { Router } from './router';
import './App.css';

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) ?? React.Fragment;

function App() {
  const dispatch = useAppDispatch();

  const userInformation = useAppSelector(selectUserInformation);

  const userId = userInformation?.id;

  useEffect(() => {
    const { hostname } = window.location;
    let releaseStage = 'production';

    if (hostname === 'localhost') {
      releaseStage = 'development';
    } else if (hostname === 'app2.claire.nowomed') {
      releaseStage = 'staging';
    }

    Bugsnag.start({
      apiKey: 'f165541ff3a93805349bf72f77614a46',
      releaseStage,
      plugins: [new BugsnagPluginReact()],
    });
    BugsnagPerformance.start({ apiKey: 'f165541ff3a93805349bf72f77614a46' });

    if (userId) {
      Bugsnag.setUser(userId);
    }
  }, [userId]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isFirstTime, setIsFirstTime] = useState(false);

  useEffect(() => {
    function handleResize() {
      const windowData = {
        isMobile: window.innerWidth < 480,
        isTablet: window.innerWidth >= 480 && window.innerWidth < 768,
        isPhablet: window.innerWidth >= 768 && window.innerWidth < 1280,
        isDesktop: window.innerWidth >= 1280,
        isLaptopScreen: window.innerWidth >= 1280 && window.innerWidth < 1800,
        isLargeScreen: window.innerWidth >= 1570,
        isMobileOrTablet: window.innerWidth < 768,
      };

      dispatch(setWindowData(windowData));
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const firstTime = localStorage.getItem('FirstTime');
    if (firstTime === null) {
      setIsFirstTime(true);
    }
  }, []);

  return (
    <div className='container'>
      <ErrorBoundary>
        <Router />
      </ErrorBoundary>
    </div>
  );
}

// eslint-disable-next-line import/no-default-export
export default App;
