import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectPrescriptionInfo, setPrescriptionInfo } from '../../../../store/reducers';
import type { ReducerAction } from '../../../../types/navigationIndex.types';
import { Button, Headline } from '../../../atoms';
import { Checkbox } from '../../../molecules/Checkbox/Checkbox';
import { ProgressStep, ProgressSteps } from '../../../organisms/ProgressSteps/ProgressSteps';
import classes from './PsychologicalSideEffects.module.scss';

const cx = classNames.bind(classes);

type PsychologicalSideEffectsProps = {
  setCurrentPage: React.Dispatch<ReducerAction>;
};

export function PsychologicalSideEffects({ setCurrentPage }: PsychologicalSideEffectsProps) {
  const prescriptionInfo = useAppSelector(selectPrescriptionInfo);
  const [prescriptionTemp, setPrescriptionTemp] = useState(prescriptionInfo);
  const dispatch = useAppDispatch();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [groupValues, setGroupValues] = useState<string[]>([]);

  useEffect(() => {
    const noPsychologicalSideEffectsSelected = !prescriptionTemp?.psychologicalSideEffects?.length;
    if (noPsychologicalSideEffectsSelected) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [prescriptionTemp]);

  const handleBackButton = () => {
    const reducerType = {
      type: '[UPDATE]',
      payload: {
        followUpPrescriptionsIndex: 2,
      },
    };
    setCurrentPage(reducerType);
  };

  const next = () => {
    dispatch(setPrescriptionInfo(prescriptionTemp));

    const reducerType = {
      type: '[UPDATE]',
      payload: {
        followUpPrescriptionsIndex: 4,
      },
    };
    setCurrentPage(reducerType);
  };

  useEffect(() => {
    setPrescriptionTemp({
      ...prescriptionTemp,
      psychologicalSideEffects: groupValues,
    });
  }, [groupValues]);

  useEffect(() => {
    setGroupValues(prescriptionInfo.psychologicalSideEffects);
  }, []);

  const toggleGroupValue = (value: string) => {
    if (value === 'keine') {
      if (groupValues.includes('keine')) {
        setGroupValues([]);

        return;
      }

      setGroupValues(['keine']);

      return;
    }

    let newGroupValues = [...groupValues];

    if (newGroupValues.includes(value)) {
      newGroupValues = newGroupValues.filter((item: string) => item !== value);
    } else {
      newGroupValues = newGroupValues.filter((item: string) => item !== 'keine');
      newGroupValues.push(value);
    }

    setGroupValues(newGroupValues);
  };

  const checkboxValue = (value: string) => {
    return groupValues.includes(value);
  };

  const SideEffectsCheckbox = ({
    children,
    value,
    extraClasses,
  }: {
    children: string;
    value: string;
    extraClasses?: string;
  }) => {
    let extraClassesSub = cx('side-effects-checkbox');

    if (extraClasses) {
      extraClassesSub += ` ${extraClasses}`;
    }

    return (
      <Checkbox
        extraClasses={extraClassesSub}
        checked={checkboxValue(value)}
        label={children}
        onClick={() => toggleGroupValue(value)}
      />
    );
  };

  return (
    <div className={cx('PsychologicalSideEffects')}>
      <ProgressSteps>
        <ProgressStep step={1} title='Fragebogen' active />
        <ProgressStep step={2} title='Apotheke' />
        <ProgressStep step={3} title='Lieferbarkeit' />
        <ProgressStep step={4} title='Bestätigung' />
        <ProgressStep step={5} title='Zahlung' />
      </ProgressSteps>
      <Headline level={3}>Psychische Nebenwirkungen</Headline>
      <div className={cx('info-text')}>
        Bitte teilen Sie Ihrem behandelnden Arzt mit, ob im Rahmen Ihrer Cannabinoid-Therapie Nebenwirkungen und
        negative Erfahrungen aufgetreten sind.
      </div>
      <div className={cx('checkboxes')}>
        <SideEffectsCheckbox value='alteredPerception'>
          Veränderte Wahrnehmung / Bewusstseinsveränderung
        </SideEffectsCheckbox>
        <SideEffectsCheckbox value='thoughtDisorders'>Denkstörungen / Gedächtnisstörungen</SideEffectsCheckbox>
        <SideEffectsCheckbox value='anxiety'>Angst und Paniksituationen</SideEffectsCheckbox>
        <SideEffectsCheckbox value='hallucinations'>Halluzinationen / Psychosen</SideEffectsCheckbox>
        <SideEffectsCheckbox extraClasses={cx('last-checkbox')} value='toleranceDevelopment'>
          Starke Toleranzentwicklung
        </SideEffectsCheckbox>
        <div className={cx('separator')}></div>
      </div>
      <div className={cx('no-side-effects')}>
        <SideEffectsCheckbox value='keine'>Nein, ich habe keine psychischen Nebenwirkungen</SideEffectsCheckbox>
      </div>
      <div className={cx('button-container')}>
        <Button secondary onClick={handleBackButton}>
          Zurück
        </Button>
        <Button disabled={isButtonDisabled} onClick={next}>
          Weiter
        </Button>
      </div>
    </div>
  );
}
