import { endOfToday } from 'date-fns';
import type { Appointment } from '../types/ninox.types';

export const daysSinceLastAppointment = (userInformation: any): number | null => {
  if (!userInformation || !userInformation.appointment || userInformation.appointment.length === 0) {
    return null;
  }

  const carriedOutAppointments = userInformation.appointment.filter(
    (appointment: Appointment) => appointment.carriedOut === 1
  );

  if (carriedOutAppointments.length === 0) {
    return null;
  }

  carriedOutAppointments.sort(
    (a: Appointment, b: Appointment) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime()
  );

  const mostRecentAppointment = carriedOutAppointments[0];
  const endDate = new Date(mostRecentAppointment.endDate);
  const today = endOfToday();

  const timeDifference = today.getTime() - endDate.getTime();
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference;
};
