import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { format, setDefaultOptions } from 'date-fns';
import { de } from 'date-fns/locale';
import type { DoctorSlot } from '../../../../types/user.types';
import { Button, Headline, Icon } from '../../../atoms';
import { ProgressStep, ProgressSteps } from '../../../organisms/ProgressSteps/ProgressSteps';
import classes from './StepThreeOfflineFirstAppointment.module.scss';

const cx = classNames.bind(classes);

const stepsOfflineAppointmentClasses = cx({ StepThreeOfflineFirstAppointment: true, StepsContainer: true });
const selectedDateContainerClasses = cx({ SelectedDateContainer: true });
const selectedDateClasses = cx({ SelectedDate: true });
const slotsContainerClasses = cx({ SlotsContainer: true });
const slotClasses = cx({ Slot: true });
const slotSelectedClasses = cx({ Slot: true, Selected: true });
const slotTextClasses = cx({ SlotText: true });
const slotDoctorClasses = cx({ SlotText: true, SlotDoctor: true });
const slotButtonClasses = cx({ SlotButton: true });

export function StepThreeOfflineFirstAppointment({
  setCurrentPage,
  firstAppointmentInfo,
  setFirstAppointmentInfo,
}: any) {
  // set locale for date-fns
  setDefaultOptions({ locale: de });

  const [selectedSlot, setSelectedSlot] = useState<DoctorSlot | undefined>();

  const getDoctorName = (id: string) => {
    const names = firstAppointmentInfo.location.doctors.split(', ');
    const index = firstAppointmentInfo.location.doctorsIds.indexOf(parseInt(id, 10));
    const trimArray = names.map((element: string) => {
      return element.trim();
    });

    return trimArray[index];
  };

  const next = () => {
    if (selectedSlot) {
      setFirstAppointmentInfo({
        ...firstAppointmentInfo,
        doctorName: getDoctorName(selectedSlot.doctorId),
        doctorId: selectedSlot.doctorId,
        selectedSlot,
      });
    }
    const reducerType = {
      type: '[UPDATE]',
      payload: {
        offlineFirstAppointmentIndex: 4,
      },
    };
    setCurrentPage(reducerType);
  };

  function sortDates(array: Array<DoctorSlot>) {
    return array.sort((a: DoctorSlot, b: DoctorSlot) => {
      if (new Date(a.start) < new Date(b.start)) {
        return -1;
      }
      if (new Date(a.start) > new Date(b.start)) {
        return 1;
      }

      return 0;
    });
  }

  const SlotInterval = ({ slot }: { slot: DoctorSlot }) => {
    return (
      <div className={classes.SlotInterval}>
        {new Date(slot.start).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        })}{' '}
        -{' '}
        {new Date(slot.end).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        })}{' '}
        Uhr
      </div>
    );
  };

  return (
    <div className={stepsOfflineAppointmentClasses}>
      <ProgressSteps>
        <ProgressStep step={1} title='Standort' active />
        <ProgressStep step={2} title='Wähle ein Datum' active />
        <ProgressStep step={3} title='Wähle eine Uhrzeit' active />
        <ProgressStep step={4} title='Bestätigung' />
        <ProgressStep step={5} title='Zahlung' />
      </ProgressSteps>
      <Headline level={3}>Wann soll Ihr Erstgespräch stattfinden?</Headline>

      <div className={selectedDateContainerClasses}>
        <div className={selectedDateClasses}>
          Verfügbare Termine am <b>{format(firstAppointmentInfo.selectedDate, 'PPP')}</b>
        </div>
        <a
          onClick={() => {
            const reducerType = {
              type: '[UPDATE]',
              payload: { offlineFirstAppointmentIndex: 2 },
            };
            setCurrentPage(reducerType);
          }}
        >
          Datum ändern
        </a>
      </div>
      <div style={{ width: '100%' }}>
        <h4>Wählen Sie bitte die Uhrzeit</h4>
      </div>
      <div className={slotsContainerClasses}>
        {sortDates(firstAppointmentInfo.doctorSlots).map((slot: DoctorSlot, i: number) => (
          <div
            className={selectedSlot === slot ? slotSelectedClasses : slotClasses}
            key={i}
            onClick={() => setSelectedSlot(slot)}
          >
            <div>
              <div className={classes.selected}>
                <div className={slotTextClasses}>
                  <SlotInterval slot={slot} />
                </div>
                <div className={slotDoctorClasses}>
                  <Icon
                    icon='user'
                    size={16}
                    color={
                      selectedSlot && slot.start === selectedSlot.start && slot.doctorId === selectedSlot.doctorId
                        ? '#ffffff'
                        : '#000000'
                    }
                  />
                  Arzt: {getDoctorName(slot.doctorId)}
                </div>
              </div>
              {selectedSlot && slot.start === selectedSlot.start && slot.doctorId === selectedSlot.doctorId && (
                <Button onClick={next} className={slotButtonClasses} inlineBlock={true}>
                  Termin buchen
                </Button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
