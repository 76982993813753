import React from 'react';
import type { ReducerAction } from '../../../../types/navigationIndex.types';
import { Button, Headline, Icon } from '../../../atoms';
import classes from './IntroPageFUP.module.scss';

type IntroPageFUPProps = {
  setCurrentPage: React.Dispatch<ReducerAction>;
};

export function IntroPageFUP({ setCurrentPage }: IntroPageFUPProps) {
  const next = () => {
    const reducerType = {
      type: '[UPDATE]',
      payload: {
        followUpPrescriptionsIndex: 2,
      },
    };
    setCurrentPage(reducerType);
  };

  return (
    <div className={classes.IntroPageFUP}>
      <div className={classes['icon-container']}>
        <Icon icon='document-plus' size={80} />
      </div>
      <Headline level={3}>In wenigen Schritten zum Folgerezept</Headline>
      <div className={classes['info-text']}>
        Um ein Folgerezept ausstellen zu können, benötigt Ihr behandelnder Arzt eine Rückmeldung über den aktuellen
        Stand der Therapie und Ihren Gesundheitszustand.
      </div>
      <div className={classes['button-container']}>
        <Button onClick={next}>Weiter</Button>
      </div>
    </div>
  );
}
