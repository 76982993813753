/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { addDays, format, startOfDay, startOfToday } from 'date-fns';
import { isPrescriptionWindowOpen } from '../../../../helper/isPrescriptionWindowOpen';
import UserService from '../../../../services/User.service';
import { Button, Loading } from '../../../atoms';
import style from './StageElements.module.scss';

type FollowUpPrescriptionProps = {
  userInformation: any;
};

const cx = classNames.bind(style);

const dividerClasses = cx({ Divider: true });
const descriptionSectionClasses = cx({ DescriptionSection: true });
const descriptionTitleClasses = cx({ DescriptionTitle: true });
const descriptionTextClasses = cx({ DescriptionText: true });
const actionButtonsClasses = cx({ ActionButtons: true });

export const FollowUpPrescription = (props: FollowUpPrescriptionProps) => {
  const { userInformation } = props;

  const navigate = useNavigate();

  const lastRequest =
    userInformation?.followUpPrescriptionRequest &&
    userInformation?.followUpPrescriptionRequest[userInformation.followUpPrescriptionRequest.length - 1];

  const lastRequestDate = lastRequest ? new Date(lastRequest.updateDate) : undefined;

  const lastAppointment = userInformation?.appointment[userInformation.appointment.length - 1];

  const lastAppointmentDate = lastAppointment ? new Date(lastAppointment.startDate) : undefined;

  // find if the last request or last appointment is most recent
  let tempDate;
  if (lastRequestDate && lastAppointmentDate) {
    if (lastRequestDate >= lastAppointmentDate) {
      tempDate = new Date(lastRequestDate);
    } else {
      tempDate = new Date(lastAppointmentDate);
    }
  } else if (lastRequestDate) {
    tempDate = new Date(lastRequest.updateDate);
  } else {
    tempDate = new Date(lastAppointment.startDate);
  }

  // the patients can ask for prescription if 3
  // days have passed since either the lastAppointment or
  // lastRequest (most recent out of 2)
  const finalDate = tempDate ? startOfDay(addDays(tempDate, 3)) : undefined;

  const today = startOfToday();
  const dateAcceptedByDoctor = lastRequest?.updateDate ? new Date(lastRequest.updateDate) : undefined;
  const hours48 = dateAcceptedByDoctor && startOfDay(addDays(dateAcceptedByDoctor, 2));

  const isLastRequestAcceptedAndWithin48Hours =
    ((lastRequest?.status === 2 || lastRequest?.status === 'accepted') && hours48 && today <= hours48) || false;

  const isLastRequestStatusUndefined = lastRequest && lastRequest.status === undefined;
  const isLastRequestPendingOrExpired =
    lastRequest?.status === 5 ||
    lastRequest?.status === 'paymentPending' ||
    lastRequest?.status === 4 ||
    lastRequest?.status === 'paymentExpired';

  const canRequestPrescription = isPrescriptionWindowOpen(userInformation);

  const PrescriptionIsSend = () => (
    <>
      <div className={descriptionSectionClasses}>
        <div className={dividerClasses} />
        <div className={descriptionTitleClasses}>Ihr Folgerezept ist unterwegs</div>
        <div className={descriptionTextClasses}>
          Ihr Folgerezept wurde ausgestellt und wird an Ihre Apotheke übermittelt.
        </div>
      </div>
    </>
  );

  const [isLoading, setLoading] = useState(false);
  const RequestPending = () => {
    const pay = async () => {
      setLoading(true);

      const response = await UserService.payPrescription(lastRequest.id);
      console.log('GOT RESPONSE', response);
      // Do the redirect if there is one.
      if (response.data.redirectUrl) {
        window.location.href = response.data.redirectUrl;

        return;
      }

      setLoading(false);

      if (response.error) {
        console.error(response.message);
      }
    };

    return (
      <div className={descriptionSectionClasses}>
        <div className={dividerClasses} />
        <div className={descriptionTitleClasses}>Ihr Folgerezept</div>
        <div className={descriptionTextClasses}>Bitte schließen Sie die Zahlung für Ihr Folgerezept ab:</div>
        <div className={actionButtonsClasses}>
          <Button disabled={isLoading} onClick={pay}>
            Zum Zahlung...
          </Button>
        </div>
      </div>
    );
  };

  const RequestMade = () => (
    <div className={descriptionSectionClasses}>
      <div className={dividerClasses} />
      <div className={descriptionTitleClasses}>Ihr Folgerezept</div>
      <div className={descriptionTextClasses}>
        Ihr Folgerezept ist angefordert und wird von Ihrem Arzt / Ihrer Ärztin geprüft.
      </div>
    </div>
  );

  const RequestAccepted = () => (
    <div className={descriptionSectionClasses}>
      <div className={dividerClasses} />
      <div className={descriptionTitleClasses}>Ihr Folgerezept</div>
      <div className={descriptionTextClasses}>
        Ihr Folgerezept ist in Bearbeitung. Nach der Ausstellung durch Ihren Arzt / Ihrer Ärztin werden Sie informiert.
      </div>
    </div>
  );

  const MakeRequest = () => {
    if (canRequestPrescription) {
      return (
        <div className={descriptionSectionClasses}>
          <div className={dividerClasses} />
          <div className={descriptionTitleClasses}>Folgerezept anfordern</div>
          <div className={descriptionTextClasses}>Fordern Sie ein Folgerezept für Ihre aktuelle Medikation an.</div>
          <div className={actionButtonsClasses}>
            <Button secondary={true} onClick={() => navigate('/follow-up-prescription')}>
              Rezept anfordern
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className={descriptionSectionClasses}>
        <div className={dividerClasses} />
        <div className={descriptionTitleClasses}>Folgerezept nicht verfügbar</div>
        <div className={descriptionTextClasses}>
          Um Ihre Therapie bestmöglich auf Sie abzustimmen und den Behandlungserfolg zu überprüfen, möchte Ihr Arzt ein
          Video-Gespräch mit Ihnen führen. Bitte buchen Sie hierfür eine Videokonsultation.
        </div>
        <div className={descriptionTextClasses}>
          Ohne ein vorheriges Video-Gespräch, ist die Ausstellung eines Folgerezepts leider nicht möglich.
        </div>
      </div>
    );
  };

  const AfterThisDateYouCanMakeTheRequest = () => (
    <div className={descriptionSectionClasses}>
      <div className={dividerClasses} />
      <div className={descriptionTitleClasses}>Folgerezept</div>
      <div className={descriptionTextClasses}>
        Ab dem {''}
        {finalDate && format(new Date(finalDate), 'dd.MM.yyyy')} {''}
        können Sie ein Folgerezept anfordern.
      </div>
    </div>
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {/*
          if the last request has not finished (status != 2), or the last request is within 48 hours
          status undefined -> the request has started but the doctor has not accept it
        */}
      {(finalDate && finalDate <= today) ||
      isLastRequestStatusUndefined ||
      isLastRequestPendingOrExpired ||
      isLastRequestAcceptedAndWithin48Hours ? (
        <>
          {isLastRequestPendingOrExpired ? (
            <RequestPending />
          ) : isLastRequestStatusUndefined ? (
            <RequestMade />
          ) : (
            <>
              {isLastRequestAcceptedAndWithin48Hours ? (
                <>
                  {!lastRequest?.prescriptionDone && <RequestAccepted />}
                  {lastRequest?.prescriptionDone && <PrescriptionIsSend />}
                </>
              ) : (
                <MakeRequest />
              )}
            </>
          )}
        </>
      ) : (
        <AfterThisDateYouCanMakeTheRequest />
      )}
    </>
  );
};
/* eslint-enable no-nested-ternary */
