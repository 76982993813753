import { daysSinceLastAppointment } from './daysSinceLastAppointment';

export const isPrescriptionWindowOpen = (userInformation: any): boolean => {
  if (!userInformation || !userInformation.appointment || userInformation.appointment.length === 0) {
    return false;
  }

  const daysSince = daysSinceLastAppointment(userInformation);

  if (daysSince === null) {
    return false;
  }

  if (!userInformation.prescriptionWindowDays) {
    return false;
  }

  return daysSince <= userInformation.prescriptionWindowDays;
};
