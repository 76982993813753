export const styles = {
  container: {
    height: '100%',
  },
  logo: {
    alignItems: 'center',
    marginVertical: 50,
    cursor: 'pointer',
  },
  labelGreen: {
    color: '#3db384',
  },
  label: {
    color: 'black',
  },
  labelWithoutIcon: {
    color: 'black',
    marginHorizontal: 57,
  },
  divider: {
    borderBottomColor: '#e0e0e0',
    borderBottomWidth: 1,
    borderRadius: 0,
  },
  versionContainer: {
    position: 'absolute',
    left: 20,
    bottom: 15,
  },
  versionText: {
    fontSize: 13,
    color: '#c2c2c2',
  },
};
