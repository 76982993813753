import type React from 'react';
import styles from './ProgressSteps.module.scss';

export function ProgressSteps({ children }: { children: React.ReactElement[] }) {
  return <ol className={styles.ProgressSteps}>{children}</ol>;
}

export function ProgressStep({
  step,
  title,
  active = false,
}: {
  step: number;
  title: string;
  active?: boolean;
}): React.ReactElement {
  return (
    <li className={active ? styles.ProgressActive : ''}>
      <span className={styles.ProgressStep}>{step}</span>
      <span className={styles.ProgressTitle}>{title}</span>
    </li>
  );
}
