import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DOCTOR_SLOT } from '../../../enums/doctorslot.enum';
import AppointmentService from '../../../services/Appointment.service';
import UserService from '../../../services/User.service';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectUserInformation, setAuth } from '../../../store/reducers';
import type { ReducerAction } from '../../../types/navigationIndex.types';
import type { Appointment, Doctor } from '../../../types/ninox.types';
import type { DoctorSlot } from '../../../types/user.types';
import { Loading } from '../../atoms';
import { Header } from '../../organisms/Header/Header';
import { StepOneOnlineAppointment } from './OnlineAppointmentSteps/StepOneOnlineAppointment';
import { StepThreeOnlineAppointment } from './OnlineAppointmentSteps/StepThreeOnlineAppointment';
import { StepTwoOnlineAppointment } from './OnlineAppointmentSteps/StepTwoOnlineAppointment';
import classes from './OnlineAppointment.module.scss';

type OnlineAppointmentProps = {
  setCurrentPage: React.Dispatch<ReducerAction>;
  currentPage: number;
};

export type AssignedDoctor = {
  doctorId: number;
  doctor: Doctor;
};

export function OnlineAppointment(props: OnlineAppointmentProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userInfo = useAppSelector(selectUserInformation);

  const [hasAppointment, setHasAppointment] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
  const [mainDoctor, setMainDoctor] = useState<{
    doctorId: number;
    doctor: Doctor;
  }>();
  const [doctorSlots, setDoctorSlots] = useState([]);
  const [displayDoctorSlots, setDisplayDoctorSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState<DoctorSlot>();
  const [slotType, setSlotType] = useState<DOCTOR_SLOT>(DOCTOR_SLOT.FOLLOW_UP_APPOINTMENT);
  const [assignedDoctor, setAssignedDoctor] = useState<AssignedDoctor>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [couponCode, setCouponCode] = useState('');

  const getDoctorSlots = (doctorId: number, paramSlotType: DOCTOR_SLOT) => {
    UserService.getDoctorOnlineSlotsBySlotType(doctorId, paramSlotType).then((res) => {
      if (res.error) {
        if (res.message.response.status === 401) {
          dispatch(setAuth({ isAuthenticated: false }));
          navigate('/login');
        }
        console.error(res.message);
      }
      if (!res.data.length) {
        navigate('/unavailable-appointment');

        return;
      }
      setDoctorSlots(res.data);

      setLoading(false);
    });
  };

  const reloadDoctorSlots = () => {
    setLoading(true);
    setSelectedSlot(undefined);
    getDoctorSlots(mainDoctor!.doctorId, slotType);
    setError(false);
  };

  const getSlotTypeByCase = (isAlreadyCannabisPatient: boolean, isFirstAppointment: boolean): DOCTOR_SLOT => {
    if (isAlreadyCannabisPatient && isFirstAppointment) {
      return DOCTOR_SLOT.FIRST_APPOINTMENT;
    }

    if ((isAlreadyCannabisPatient && !isFirstAppointment) || (!isAlreadyCannabisPatient && !isFirstAppointment)) {
      return DOCTOR_SLOT.FOLLOW_UP_APPOINTMENT;
    }

    if (!isAlreadyCannabisPatient && isFirstAppointment) {
      return DOCTOR_SLOT.FIRST_APPOINTMENT;
    }

    return DOCTOR_SLOT.FOLLOW_UP_APPOINTMENT;
  };

  const getAllAppointments = async (): Promise<Appointment[]> => {
    const appointmentsData = await AppointmentService.getAllAppointments();

    if (appointmentsData.error) {
      dispatch(setAuth({ isAuthenticated: false }));
      navigate('/login');
    }

    return appointmentsData.data;
  };

  /*  const isNextDay = (date: string) => {
    return new Date(date).setHours(23, 59, 59, 0) < new Date().setHours(0, 0, 0, 0);
  }; */

  const checkHasActualAppointment = (appointmentData: Appointment[]): boolean =>
    new Date(appointmentData[appointmentData.length - 1].endDate) > new Date();

  useEffect(() => {
    setLoading(true);

    // always start on page 1 when component is mounted
    const reducerType = {
      type: '[UPDATE]',
      payload: {
        onlineAppointmentIndex: 1,
      },
    };
    props.setCurrentPage(reducerType);

    (async () => {
      const appointmentsData = await getAllAppointments();
      const isFirstAppointment = appointmentsData.length === 0;
      if (!isFirstAppointment) {
        const hasActualAppointment = checkHasActualAppointment(appointmentsData);
        if (hasActualAppointment) {
          navigate('/dashboard');

          return;
        }
      }

      UserService.getDoctors()
        .then((res) => {
          if (res.error) {
            if (res.message.response.status === 401) {
              dispatch(setAuth({ isAuthenticated: false }));
              navigate('/login');
            }
            console.error(res.message);
          }

          if (!res.data || res.data.length === 0) {
            navigate('/error', {
              state: { message: 'Bitte vereinbaren Sie einen Ersttermin, bevor Sie einen Folgetermin vereinbaren.' },
            });

            return;
          }

          const mainDoc = res.data.find(
            (doctor: { doctorType: string | number }) => doctor.doctorType === 'main' || doctor.doctorType === 1
          );
          // mainDoc can be undefined

          const { isAlreadyCannabisPatient } = userInfo;
          const localSlotType = getSlotTypeByCase(isAlreadyCannabisPatient!, isFirstAppointment);
          setSlotType(localSlotType);
          setMainDoctor(mainDoc);
          // setDoctors(res.data);
          getDoctorSlots(mainDoc.doctorId, slotType);
        })
        .catch((err) => {
          console.error(err.message);
        });
    })();
  }, []);

  useEffect(() => {
    const slots = doctorSlots.filter(
      (slot: DoctorSlot) =>
        new Date(new Date(slot.start).setHours(0, 0, 0, 0)).getTime() === new Date(selectedDate).getTime()
    );
    slots.sort((a: DoctorSlot, b: DoctorSlot) => new Date(a.start).getTime() - new Date(b.start).getTime());
    setSelectedSlot(undefined);
    setDisplayDoctorSlots(slots);
  }, [selectedDate]);

  if (loading) {
    return <Loading />;
  }

  const bookAppointment = async () => {
    setSaving(true);

    const appointments = await getAllAppointments();

    const hasAppointments = appointments.length > 0;
    if (hasAppointments) {
      const hasActualAppointment = checkHasActualAppointment(appointments);
      if (hasActualAppointment) {
        setHasAppointment(true);
        setSaving(false);

        return;
      }
    }

    if (selectedSlot) {
      const successPage = '/dashboard';

      const appointmentData = {
        doctorName: '',
        doctorId: 0,
        startDate: selectedSlot.start,
        endDate: selectedSlot.end,
        comment: 'Von Patient erstellt',
        type: 'online',
        couponCode,
        appointmentType: slotType === DOCTOR_SLOT.FIRST_APPOINTMENT ? 'firstAppointment' : 'followUpAppointment',
        redirectUrl: new URL(successPage, `https://${window.location.host}`).href,
      };
      if (parseInt(selectedSlot.doctorId, 10) === mainDoctor?.doctorId) {
        appointmentData.doctorName = `${mainDoctor!.doctor.firstname}${mainDoctor!.doctor.lastname}`;
        appointmentData.doctorId = mainDoctor!.doctorId;
      } else {
        appointmentData.doctorName = `${assignedDoctor!.doctor.firstname}${assignedDoctor!.doctor.lastname}`;
        appointmentData.doctorId = parseInt(selectedSlot.doctorId, 10);

        const doctorResponse = await UserService.assignDoctorWithType(
          parseInt(userInfo.id, 10),
          parseInt(selectedSlot.doctorId, 10),
          'sub'
        );

        if (doctorResponse.error) {
          console.error(doctorResponse);
          setLoading(false);

          return;
        }
      }

      AppointmentService.createAppointment(appointmentData).then((res) => {
        if (res.error) {
          setError(true);
          if (res.message.response.status === 401) {
            dispatch(setAuth({ isAuthenticated: false }));
            navigate('/login');
            setError(false);
          }
          console.error(res.message);
        }
        setSaving(false);

        if (res.data.data.redirectUrl) {
          window.location.href = res.data.data.redirectUrl;
        } else {
          navigate(successPage);
        }
      });
    }
  };

  let pageToRender;

  switch (props.currentPage) {
    case 1:
      pageToRender = (
        <StepOneOnlineAppointment
          setCurrentPage={props.setCurrentPage}
          hasAppointment={hasAppointment}
          setHasAppointment={setHasAppointment}
          doctorSlots={doctorSlots}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          mainDoctor={mainDoctor?.doctorId}
        />
      );
      break;
    case 2:
      pageToRender = (
        <StepTwoOnlineAppointment
          setCurrentPage={props.setCurrentPage}
          error={error}
          setError={setError}
          reloadDoctorSlots={reloadDoctorSlots}
          displayDoctorSlots={displayDoctorSlots}
          selectedSlot={selectedSlot}
          setSelectedSlot={setSelectedSlot}
          selectedDate={selectedDate}
        />
      );
      break;
    case 3:
      pageToRender = (
        <StepThreeOnlineAppointment
          setCurrentPage={props.setCurrentPage}
          isSaving={isSaving}
          selectedSlot={selectedSlot}
          bookAppointment={bookAppointment}
          assignedDoctor={assignedDoctor}
          setAssignedDoctor={setAssignedDoctor}
          couponCode={couponCode}
          setCouponCode={setCouponCode}
        />
      );
      break;
    default:
      pageToRender = <React.Fragment></React.Fragment>;
      break;
  }

  return (
    <React.Fragment>
      <Header barTitle='Videogespräch vereinbaren' showBackButton={true} />
      <div className={classes.OnlineAppointment}>{pageToRender}</div>
    </React.Fragment>
  );
}
